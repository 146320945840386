/* global $, MatgenGlobal */

import { init as keysInit } from './key-events.js';
import { emit } from '../../../matgen-ui/common/helpers.js';
import { initIconPicker } from '../../ui-functions.js';
import { SidebarHeader } from '../../components/Sidebar/SidebarHeader.js';

function genericClickHandler(e) {
  if (!MatgenGlobal.focusElement) {
    if (!MatgenGlobal.SuppressTooltips) {
      $('[data-bs-toggle="tooltip"]').tooltip('hide');
      MatgenGlobal.hideTooltips();
    } else {
      MatgenGlobal.hideTooltips();
    }

    if (
      e.target.tagName !== 'CANVAS' &&
      MatgenGlobal.editor &&
      MatgenGlobal.editor.cur() &&
      $(e.target).parents('.tree-li').length === 0
    ) {
      MatgenGlobal.editor.cur().hoverOutline(e, true);
      MatgenGlobal.editor.cur().fabric.discardActiveObject();
      MatgenGlobal.editor.cur().fabric.requestRenderAll();
    }
  }
}

const decode = str => {
  return $('<textarea/>')
    .html(str)
    .text();
};

const init = () => {
  $(document).off('click', '#toggle-icon-chooser');
  $(document).on('click', '#toggle-icon-chooser', () => {
    initIconPicker();
  });

  $(document).off('change', 'select');
  $(document).on('change', 'select', e => {
    if (
      $(e.currentTarget)
        .find('option:selected')
        .hasClass('default')
    ) {
      $(e.currentTarget).css('color', '#aaa');
    } else {
      $(e.currentTarget).css('color', 'inherit');
    }
  });

  $(document).on('show.bs.modal, show-page', () => {
    $('select').each((i, el) => {
      if (
        $(el)
          .find('option:selected')
          .hasClass('default')
      ) {
        $(el).css('color', '#aaa');
      } else {
        $(el).css('color', 'inherit');
      }
    });
  });

  $(document).off('matgen-signin');
  $(document).on('matgen-signin', () => {
    MatgenGlobal.CommonInit();
    MatgenGlobal.EditorInit();
  });

  $(document).off('matgen-canvas-mouse-out');
  $(document).on('matgen-canvas-mouse-out', async e => {
    MatgenGlobal.editor.cur().hoverOutline(e, true);
  });

  $(document).off('click', genericClickHandler);
  $(document).on('click', genericClickHandler);

  if (
    MatgenGlobal.AuthUser &&
    MatgenGlobal.AuthUser.getUserRole() === 'super'
  ) {
    keysInit();
  }

  $(document).off('object-left-click');
  $(document).off('object-right-click');
  if (
    MatgenGlobal.AuthUser &&
    MatgenGlobal.AuthUser.getUserRole() === 'super'
  ) {
    $(document).on(
      'object-right-click',
      MatgenGlobal.containerSelector,
      async e => {
        if (e.detail.canvasEvent.target.type === 'textbox') {
          e.preventDefault();
          MatgenGlobal.UI.textForm({
            edit: true,
            id: e.detail.canvasEvent.target.id,
          });
        }
      }
    );
  } else if (
    MatgenGlobal.AuthUser &&
    MatgenGlobal.AuthUser.getUserRole() === 'admin'
  ) {
    $(document).on('object-left-click', () => {
      if ($('#context-menu').length > 0 && !window.contextMenu) {
        $('#context-menu').remove();
      } else {
        window.contextMenu = false;
      }
    });

    $(document).on(
      'object-left-click',
      MatgenGlobal.containerSelector,
      async () => {
        const curObj = MatgenGlobal.editor.cur().fabric.getActiveObject();
        if (curObj && curObj.componentId) {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading component options display',
            promise: MatgenGlobal.UI.showComponentOptions(curObj.componentId),
          });
        } else if (curObj.userEditable) {
          MatgenGlobal.UI.alertModal(
            'User Editable Text Component',
            '<p>This is a user editable text component. No actions available.</p>'
          );
        } else if (curObj.uploader) {
          MatgenGlobal.UI.alertModal(
            'User Image Uploader Component',
            '<p>This is a user image uploader component. No actions available.</p>'
          );
        }
      }
    );
  } else if (
    MatgenGlobal.AuthUser &&
    (!MatgenGlobal.AuthUser.user ||
      MatgenGlobal.AuthUser.getUserRole() === 'user')
  ) {
    $(document).on(
      'object-left-click',
      MatgenGlobal.containerSelector,
      async e => {
        const curObj = MatgenGlobal.editor.cur().fabric.getActiveObject();
        if (
          e.detail.canvasEvent.target.type === 'textbox' &&
          e.detail.canvasEvent.target.editable &&
          (!curObj || !curObj.studyDataConnection)
        ) {
          MatgenGlobal.editor
            .cur()
            .fabric.setActiveObject(e.detail.canvasEvent.target);
          MatgenGlobal.editor.cur().fabric.renderAll();
          // if (!MatgenGlobal.editor.cur().fabric.getActiveObject().isEditing) {
          //   MatgenGlobal.editor
          //     .cur()
          //     .fabric.getActiveObject()
          //     .enterEditing()
          //     .selectAll();
          // }
        } else if (e.detail.canvasEvent.target.richText) {
          if (
            MatgenGlobal &&
            MatgenGlobal.PassModals &&
            Array.isArray(MatgenGlobal.PassModals) &&
            MatgenGlobal.PassModals.includes('richtext')
          ) {
            emit({
              event: 'matgen-event-richtext-modal',
              detail: e.detail.canvasEvent.target,
            });
          } else {
            SidebarHeader.richTextModal();
          }
        } else if (e.detail.canvasEvent.target.uploader) {
          MatgenGlobal.UI.userUpload();
        } else if (curObj && curObj.componentId && curObj.hasOptions) {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading component options display',
            promise: MatgenGlobal.UI.showComponentOptions(curObj.componentId),
          });
        } else if (curObj && curObj.studyDataConnection) {
          if (curObj.type === 'textbox') {
            let studyData =
              MatgenGlobal.microsite_vars[curObj.studyDataConnection];

            const joinData = ['primary_contact', 'health_status'];
            if (joinData.includes(curObj.studyDataConnection)) {
              studyData = studyData.join('\n');
            } else if (curObj.studyDataConnection === 'investigators') {
              studyData = MatgenGlobal.microsite_vars[
                curObj.studyDataConnection
              ][0]
                .map(o => {
                  return `${decode(o.name).replace(
                    ' (not required)',
                    ''
                  )}: ${decode(o.value)}`;
                })
                .join('\n');
            } else if (curObj.studyDataConnection === 'locations') {
              const states = Object.keys(
                MatgenGlobal.microsite_vars[curObj.studyDataConnection]
              );
              const output = [];
              for (let i = 0; i < states.length; i++) {
                output.push(states[i]);
                const locs =
                  MatgenGlobal.microsite_vars[curObj.studyDataConnection][
                    states[i]
                  ];
                for (let j = 0; j < locs.length; j++) {
                  const loc = locs[j];
                  for (let k = 0; k < loc.length; k++) {
                    const json = JSON.parse(loc[k].text);
                    output.push(`${json.label}: ${loc[k].userAnswer}`);
                  }
                  output.push('\n');
                }
                output.push('\n');
              }
              studyData = output.join('\n');
            } else if (curObj.studyDataConnection === 'faq') {
              studyData = MatgenGlobal.microsite_vars[
                curObj.studyDataConnection
              ]
                .map(o => `${o.text}: ${o.userAnswer}`)
                .join('\n\n');
            }

            MatgenGlobal.UI.userStudyDataTextForm(
              curObj.id,
              studyData,
              curObj.fontSize
            );
          } else if (curObj.type === 'image') {
            MatgenGlobal.UI.userUpload();
          }
        }
      }
    );
  }

  $(document).off('click', '.table-action');
  $(document).on('click', '.table-action', e => {
    e.preventDefault();
    const closest = $(e.target).closest('[data-action]');
    const action = closest.attr('data-action');
    const id = closest.attr('data-id');
    const alt_id = closest.attr('data-alt-id');
    const tenant_id = closest.attr('data-tenant-id');
    const row = e.target.closest('tr');

    const data = closest.data();
    emit({
      event: 'matgen-table-action',
      detail: {
        action,
        id,
        row,
        alt_id,
        data,
        tenant_id,
      },
    });
  });

  $(document).off('click', '.thumb-page');
  $(document).on('click', '.thumb-page', async e => {
    e.preventDefault();
    if ($(e.target).attr('data-tenant-id')) {
      const ext = '.png';
      $('#page-thumb').attr(
        'src',
        `${MatgenGlobal.Data.getTemplateFileURL(
          $(e.target).attr('data-id'),
          $(e.target).attr('data-tenant-id'),
          ext
        )}`
      );
    } else if ($(e.target).attr('data-page-id')) {
      $('#page-thumb').attr(
        'src',
        `${await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading preview',
          promise: MatgenGlobal.Data.getMaterialPreviewURL(
            $(e.target).attr('data-id'),
            $(e.target).attr('data-page-id')
          ),
        })}`
      );
    } else {
      throw new Error('Missing tenant or page ID');
    }
  });

  $(document).off('mouseleave', MatgenGlobal.containerSelector);
  $(document).on('mouseleave', MatgenGlobal.containerSelector, () => {
    if (
      MatgenGlobal.editor &&
      MatgenGlobal.editor.cur() &&
      !MatgenGlobal.editor.cur().fabric.getActiveObject()
    ) {
      $('.tree-li').removeClass('active');
    }
  });

  $(document).off('selection-changed', MatgenGlobal.containerSelector);
  $(document).on('selection-changed', MatgenGlobal.containerSelector, e => {
    let objects = [e.detail.canvasEvent.target];
    if (e.detail.canvasEvent.target.getObjects) {
      objects = e.detail.canvasEvent.target
        .getObjects()
        .filter(o => !o.sidebarIgnore);
    }
    $('.tree-li').removeClass('active');
    objects.forEach(o => {
      $(`#${o.id}`).addClass('active');
      $(`#${o.componentId}`).addClass('active');
      $(`#gcid-${o.componentId}`).addClass('active');
    });
  });

  $(document).off('selection-cleared', MatgenGlobal.containerSelector);
  $(document).on('selection-cleared', MatgenGlobal.containerSelector, () => {
    $('.tree-li').removeClass('active');
  });
};

export { init };
