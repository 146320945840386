/* global MatgenGlobal */

import { emit } from '../matgen-ui/common/helpers.js';
import memoize from 'memoizee';

const memoizedGetUser = memoize(
  () => MatgenGlobal.Amplify.Auth.currentAuthenticatedUser(),
  {
    maxAge: 300000,
    promise: true,
  }
);

window.getUser = memoizedGetUser;

class M4CAmplifyUser {
  constructor(token = false, identity_id = false, expiresIn = 60) {
    this.user = false;
    this.overrideRole = false;
    this.overrideTenant = false;
    this.token = token;
    this.identity_id = identity_id;
    this.expiresIn = expiresIn;
  }

  async getUser(group = 'get-user') {
    let user = false;
    if (!MatgenGlobal.AuthUser.user) {
      const override = sessionStorage.getItem('overrideRole');
      if (override) {
        this.overrideRole = override;
      }
      try {
        if (this.token !== false && this.identity_id !== false && !this.user) {
          user = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Logging in',
            group: 'matgen-login',
            promise: MatgenGlobal.Amplify.Auth.federatedSignIn('developer', {
              token: this.token,
              identity_id: this.identity_id,
              expires_at: this.expiresIn * 60000 + new Date().getTime(),
            }),
          });
          this.user = user;
          MatgenGlobal.AuthUser.user = user;
        } else {
          try {
            const user = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading current user',
              group,
              promise: memoizedGetUser,
              suppressError: true,
            });
            //const user = await MatgenGlobal.Amplify.Auth.currentAuthenticatedUser();
            this.user = user;
            MatgenGlobal.AuthUser.user = user;
            return user;
          } catch (e) {
            return false;
          }
        }
        return user;
      } catch (e) {
        console.error(Error(e));
        return false;
      }
    }
  }

  isM4CSuper() {
    let is = false;
    const groups = this.getUserGroups();
    if (
      groups &&
      this.user &&
      this.user.attributes &&
      this.user.attributes['custom:tenant'] &&
      this.user.attributes['custom:tenant'].toLowerCase() === 'm4c' &&
      groups.includes('m4c') &&
      (groups.includes('master') || groups.includes('super'))
    ) {
      is = true;
    }
    return is;
  }
  hasRole(role) {
    let hasRole = false;
    const groups = this.getUserGroups();
    if (groups) {
      if (
        groups.includes(role) ||
        (this.isM4CSuper() && role === this.overrideRole)
      ) {
        hasRole = true;
      }
    }
    return hasRole;
  }
  getUserTenant() {
    if (this.overrideTenant && this.isM4CSuper()) {
      return this.overrideTenant;
    }
    if (this.user && this.user.attributes) {
      return this.user.attributes['custom:tenant'];
    }
    return false;
  }
  getUserRole() {
    if (this.overrideRole && this.isM4CSuper()) {
      return this.overrideRole;
    }
    const groups = this.getUserGroups();
    if (groups) {
      if (groups.includes('admin')) {
        return 'admin';
      } else if (groups.includes('super')) {
        return 'super';
      } else if (groups.includes('master')) {
        return 'master';
      }
    }
    return 'user';
  }
  getUserToken() {
    if (
      this.user &&
      this.user.signInUserSession &&
      this.user.signInUserSession.idToken
    ) {
      return this.user.signInUserSession.idToken.jwtToken;
    }
    return false;
  }

  getSession() {
    return MatgenGlobal.Amplify.Auth.currentSession();
  }

  async authHeaderOpts() {
    try {
      const session = await MatgenGlobal.Amplify.Auth.currentSession();
      const token = session.idToken.jwtToken;
      const opts = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return opts;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  async getSessionToken() {
    let session = false;
    if (!session) {
      try {
        session = await MatgenGlobal.Amplify.Auth.currentSession();
        return session.idToken.jwtToken;
      } catch (e) {
        return false;
      }
    }
  }

  async signUp(username, password, tenant, metadata = false) {
    try {
      const user = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Processing signup',
        promise: MatgenGlobal.Amplify.Auth.signUp({
          username,
          password,
          attributes: {
            email: username,
            'custom:tenant': tenant,
          },
          clientMetadata: metadata,
        }),
      });
      this.user = user;
      MatgenGlobal.AuthUser.user = this.user;

      emit({
        event: 'matgen-event-signup',
      });
      return true;
    } catch (error) {
      console.error('Registration error:', error);
      return error;
    }
  }

  async signIn(username, password) {
    let user;
    try {
      user = await MatgenGlobal.Amplify.Auth.signIn(username, password);
      this.user = user;
      MatgenGlobal.AuthUser.user = user;
      let response = true;
      if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        response = 'NEW_PASSWORD_REQUIRED';
      }
      if (
        this.user.__type &&
        this.user.__type === 'UserNotConfirmedException'
      ) {
        return 'UNCONFIRMED_EMAIL';
      }
      emit({
        event: 'matgen-signin',
      });
      if (
        MatgenGlobal.MatgenControllerUI &&
        MatgenGlobal.MatgenControllerUI.authUI &&
        typeof MatgenGlobal.MatgenControllerUI.authUI === 'function'
      ) {
        MatgenGlobal.MatgenControllerUI.authUI();
      }
      return response;
    } catch (error) {
      console.error('SIGNIN ERROR:', error);
      if (error.code === 'PasswordResetRequiredException') {
        return 'RESET_PASSWORD_REQUIRED';
      }
      if (error.code === 'UserNotConfirmedException') {
        return 'UNCONFIRMED_EMAIL';
      }
      console.error('Cognito signin failure:', error);
      return error;
    }
  }

  async signOut(global = false, cb = false) {
    sessionStorage.clear();
    try {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Signing out',
        promise: MatgenGlobal.Amplify.Auth.signOut({ global }),
      });
      MatgenGlobal.AuthUser.user = false;
      emit({
        event: 'matgen-signout',
      });

      if (cb && typeof cb === 'function') {
        cb();
      } else {
        MatgenGlobal.Router.goTo('/unauth');
      }
      return true;
    } catch (error) {
      console.error('Cognito signout failure:', error);
      if (cb && typeof cb === 'function') {
        cb();
      } else {
        MatgenGlobal.Router.goTo('/unauth');
      }
      return false;
    }
  }

  async forgotPassword(username) {
    try {
      this.confirmation = await MatgenGlobal.Amplify.Auth.forgotPassword(
        username
      );
      return true;
    } catch (error) {
      console.error('Cognito forgot password failure:', error);
      return error;
    }
  }

  async forgotPasswordConfirm(username, code, password) {
    try {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Resetting password',
        promise: MatgenGlobal.Amplify.Auth.forgotPasswordSubmit(
          username,
          code,
          password
        ),
      });
      return true;
    } catch (error) {
      console.error('Cognito forgot password confirm failure:', error);
      return error;
    }
  }

  static async resendConfirmationCode(username) {
    try {
      const metadata = {};
      if (MatgenGlobal.AMPLIFY_VARS.redirectSignIn) {
        metadata.redirect = MatgenGlobal.AMPLIFY_VARS.redirectSignIn;
      }
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Sending verification email',
        promise: MatgenGlobal.Amplify.Auth.resendSignUp(username, metadata),
      });
      MatgenGlobal.UI.alertModal(
        null,
        MatgenGlobal.UI.bootstrapAlertHTML(
          'success',
          MatgenGlobal.successIcon
            ? MatgenGlobal.successIcon
            : 'fa-duotone fa-circle-check',
          'The verification email has been sent. Please allow a few minutes for delivery.'
        )
      );
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        `<p>There was an error sending the verification email. Please try again later, or contact support.</p>
        `
      );
    }
  }

  getUserGroups() {
    try {
      return MatgenGlobal.Amplify.Auth.user.signInUserSession.accessToken
        .payload['cognito:groups'];
    } catch (e) {
      return null;
    }
  }

  static validateSignup({
    email_element,
    email_confirm_element,
    password_element,
    password_confirm_element,
  }) {
    if (email_element.value != email_confirm_element.value) {
      email_confirm_element.setCustomValidity("Email addresses don't match");
      return false;
    } else {
      email_confirm_element.setCustomValidity('');
    }

    if (password_element.value != password_confirm_element.value) {
      password_confirm_element.setCustomValidity("Passwords don't match");
      return false;
    } else {
      password_confirm_element.setCustomValidity('');
    }

    return true;
  }
}

export default M4CAmplifyUser;
