/* global $, bootstrap */

class M4CModal {
  static show({
    id,
    title,
    content,
    buttons = [],
    classes = '',
    header = true,
    footer = true,
    closeButton = false,
    closeText = 'Close',
    closeX = true,
    target = 'body',
    width = 'fit-content',
    matgen = false,
  } = {}) {
    const html = M4CModal.getHTML({
      id,
      title,
      content,
      buttons,
      classes,
      header,
      footer,
      closeButton,
      width,
      closeText,
      closeX,
      matgen,
    });
    $(target).append($(html));
    const myModal = new bootstrap.Modal(document.getElementById(id));
    myModal.show();

    $(`#${id}`).on('show.bs.modal', () => {
      $('.tooltip').remove();
    });

    $(`#${id}`).on('hide.bs.modal', () => {
      $('.tooltip').remove();
    });
  }

  static getHTML({
    id,
    title,
    content,
    buttons = [],
    header = true,
    footer = true,
    width = 'fit-content',
  } = {}) {
    content = `
      ${content}
      ${
        header
          ? ''
          : `
      <button type="button" class="btn-close alert-modal-close-button matgen-core" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        `
      }
    `;
    return `
      <div class="modal revised fade m4c-matgen" id="${id}" tabindex="-1" role="dialog" aria-labelledby="${id}-label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
          <div class="modal-content"  style="width:${width};">


            ${
              header
                ? `
              <div class="modal-header">
                ${
                  title
                    ? `<h4 class="modal-title" id="${id}-label">${title}</h4>`
                    : ''
                }
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            `
                : ''
            }

            <div class="modal-body" role="document">
            ${content}
            </div>

            ${
              footer && buttons.length
                ? `
              <div class="modal-footer">
              ${buttons
                .map(button => {
                  const classes = button.classes || button.classname;
                  return `<button ${
                    button.attributes ? button.attributes.join(' ') : ''
                  } id="${button.id}" type="button" class="${classes}">${
                    button.label
                  }</button>`;
                })
                .join('')}

              </div>
              `
                : ''
            }
          </div>
        </div>
      </div>
      `;
  }
}

export { M4CModal };
