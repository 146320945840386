/* global $, MatgenGlobal */

import baseRoutes from './router-config.js';

class RouterCore {
  init({ config = {}, routerInit = () => {}, goTo = () => {} } = {}) {
    this.config = baseRoutes;
    this.goTo = goTo;
    if (Object.keys(config).length !== 0) {
      this.config = this.addRoutes(config);
    }

    const _this = this;
    (function() {
      const rs = history.replaceState;
      history.replaceState = function() {
        MatgenGlobal.PreviousStateId = MatgenGlobal.StateId;
        MatgenGlobal.StateId++;
        arguments[0].stateId = MatgenGlobal.StateId;
        rs.apply(history, arguments);
        _this.handleNavigation('REPLACE STATE', arguments);
      };
    })();

    (function() {
      const rs = history.pushState;
      history.pushState = function() {
        MatgenGlobal.PreviousStateId = MatgenGlobal.StateId;
        MatgenGlobal.StateId++;
        arguments[0].stateId = MatgenGlobal.StateId;
        rs.apply(history, arguments);
        _this.handleNavigation('PUSH STATE', arguments);
      };
    })();

    $(document).ready(() => {
      _this.handleNavigation('PAGE LOAD');
    });

    window.onpopstate = function(e) {
      if (e.state) {
        _this.handleNavigation('POP STATE', e);
      }
    };

    window.onhashchange = function(e) {
      _this.handleNavigation('HASH CHANGE', e);
    };
    routerInit(this.config);
  }

  handleNavigation(action, e) {
    document.dispatchEvent(
      new CustomEvent('matgen-navigation', {
        composed: true,
        detail: {
          location: window.location,
          action,
          e,
        },
      })
    );
  }

  addRoutes(routes) {
    const config = baseRoutes;
    const saveCatchAll = Object.assign({}, config.notFound);
    delete config.notFound;
    Object.keys(routes).forEach(r => {
      config[r] = routes[r];
    });
    if (!config.notFound) {
      config.notFound = saveCatchAll;
    }
    return config;
  }

  reload() {
    window.location.href = `${new URL(window.location.pathname)}${
      location.hash ? location.hash : ''
    }`;
  }

  emitRouteEvent(route) {
    document.dispatchEvent(
      new CustomEvent('matgen-route', {
        composed: true,
        detail: { route },
      })
    );
  }
}

export { RouterCore };
