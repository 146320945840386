/* global $, MatgenGlobal */

import { M4CTable } from '../M4CTable.js';

const TemplateActions = item => {
  let desc;
  let mod;
  try {
    if (item.description) {
      desc = JSON.parse(item.description);
    }
    if (item.modifiable) {
      mod = JSON.parse(item.modifiable);
    }
  } catch (e) {
    console.error(e);
    MatgenGlobal.UI.handleError(
      'Error loading template description and/or modifiable data.',
      'There was a problem the template description/modifiable content.'
    );
    return false;
  }

  /*if (desc) {
    console.log(
      'DESC',
      item.id,
      desc.plain,
      desc.plain.trim(),
      (desc && desc.plain && /\S/.test(desc.plain) && !!desc.plain.trim()) ||
        (mod &&
          mod.plain &&
          mod.plain !== '' &&
          /\S/.test(mod.plain) &&
          !!mod.plain.trim())
        ? 'primary'
        : 'warning'
    );
  }*/

  let actions = [
    {
      action: 'download',
      title: 'Download Template',
      icon: 'far fa-file-download',
      color: 'primary',
    },
    {
      action: 'view',
      title: 'Preview Template',
      icon: 'far fa-image',
      color: 'primary',
    },
    {
      action: 'images',
      title: 'Download Images',
      icon: 'far fa-images',
      color: 'primary',
    },
    {
      action: 'edit-data',
      title: 'Edit Template Data',
      icon: 'fas fa-edit',
      color: 'primary',
      role: 'super',
    },
    {
      action: 'edit-name',
      title: 'Edit Template Name',
      icon: 'fas fa-edit',
      color: 'primary',
      role: 'admin',
    },
    {
      action: 'edit',
      title: 'Edit Template',
      icon: 'fas fa-paint-brush',
      color: 'primary',
      classes: 'hide-file-type',
    },
    {
      action: 'toggle-publish',
      title: item.published === 1 ? 'Unpublish' : 'Publish',
      icon: item.published === 1 ? 'fas fa-eye-slash' : 'fas fa-eye',
      color: item.published === 1 ? 'primary' : 'warning',
    },
    {
      action: 'tags',
      title: 'Manage Tags',
      icon: 'fas fa-tags',
      color: item.tag_count > 0 ? 'primary' : 'warning',
    },
    {
      action: 'description',
      title: 'Manage Description / Modifiable',
      icon: 'fas fa-block-quote',
      color:
        desc &&
        desc.plain &&
        /\S/.test(desc.plain) &&
        !!desc.plain.trim() &&
        mod &&
        mod.plain &&
        mod.plain !== '' &&
        /\S/.test(mod.plain) &&
        !!mod.plain.trim()
          ? 'primary'
          : 'warning',
    },
    {
      action: 'review',
      title: 'Enable/Disable QA Visibility',
      icon: 'fas fa-q',
      color: item.review === 1 ? 'danger' : 'warning',
    },
    {
      action: 'duplicate',
      title: 'Duplicate Template',
      icon: 'fas fa-copy',
      color: 'primary',
      role: 'super',
    },
    {
      action: 'export',
      title: 'Export Template',
      icon: 'fas fa-file-export',
      color: 'primary',
      role: 'super',
    },
    {
      action: 'delete',
      title: 'Delete Template',
      icon: 'fas fa-trash',
      color: 'danger',
      role: 'super',
    },
  ];
  if (MatgenGlobal.CustomTemplateActions) {
    const custom = MatgenGlobal.CustomTemplateActions(item);
    actions = custom.concat(actions);
  }
  return actions;
};
const TemplateColumns = [
  {
    data: 'tenant_name',
    title: 'T',
    width: '2em',
    render: item => item.toUpperCase(),
  },
  { data: 'id', title: 'ID', className: 'nowrap' },
  { data: 'name', title: 'Name' },
  {
    data: 'type',
    title: 'D',
    width: '2em',
    render: item => item.toUpperCase(),
  },
  {
    data: 'created',
    title: 'Crtd',
    type: 'date',
    width: '4.5em',
    render: item => new Date(item).toLocaleString('en-US'),
  },
  { data: 'sort_order', title: 'S', width: '1em' },
  {
    data: 'goal_tags',
    title: 'Goal',
  },
  {
    data: 'race_tags',
    title: 'Race',
  },
  {
    data: 'health_tags',
    title: 'Health',
  },
  {
    data: 'language_tags',
    title: 'Lang',
  },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item =>
      M4CTable.actionsHTML(item, 'template', TemplateActions(item)),
  },
];

const TemplateTable = new M4CTable({
  columns: TemplateColumns,
  table: 'templates',
  title: 'Templates',
  callbacks: {
    createdRow: (row, data) => {
      if (data.published !== 1) {
        $(row).addClass('unpublished');
        $(row)
          .find('[data-action="template-toggle-publish"]')
          .removeClass('btn-primary')
          .addClass('btn-warning');
      } else {
        $(row)
          .find('[data-action="template-toggle-publish"]')
          .addClass('btn-primary')
          .removeClass('btn-warning');
      }
      if (data.tag_count === 0) {
        $(row).addClass('untagged');
      }
      if (data.type === 'FILE') {
        $(row).addClass('file-type');
      }
    },
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
    initComplete: s => {
      s.oInstance
        .api()
        .order([4, 'desc'])
        .draw();
    },
  },
});

export { TemplateTable };
