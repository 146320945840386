/* global $, MatgenGlobal */

import { M4CTable } from '../M4CTable.js';

const actions = item => {
  let desc;
  let mod;
  try {
    if (item.description) {
      desc = JSON.parse(item.description);
    }
    if (item.modifiable) {
      mod = JSON.parse(item.modifiable);
    }
  } catch (e) {
    console.error(e);
    MatgenGlobal.UI.handleError(
      'Error loading template description and/or modifiable data.',
      'There was a problem the template description/modifiable content.'
    );
    return false;
  }

  if (desc) {
    console.log(
      'DESC',
      item.id,
      desc.plain,
      desc.plain.trim(),
      (desc && desc.plain && /\S/.test(desc.plain) && !!desc.plain.trim()) ||
        (mod &&
          mod.plain &&
          mod.plain !== '' &&
          /\S/.test(mod.plain) &&
          !!mod.plain.trim())
        ? 'primary'
        : 'warning'
    );
  }

  let actions = [
    {
      action: 'download',
      title: 'Download Report',
      icon: 'far fa-file-download',
      color: 'primary',
    },
    {
      action: 'edit-name',
      title: 'Edit Report',
      icon: 'fas fa-edit',
      color: 'primary',
      role: 'admin',
    },
    {
      action: 'edit',
      title: 'Edit Report',
      icon: 'fas fa-pencil',
      color: 'primary',
      classes: 'hide-file-type',
    },
    {
      action: 'delete',
      title: 'Delete Template',
      icon: 'fas fa-trash',
      color: 'danger',
      role: 'super',
    },
  ];
  if (MatgenGlobal.Customactions) {
    const custom = MatgenGlobal.Customactions(item);
    actions = custom.concat(actions);
  }
  return actions;
};

const columns = [
  { data: 'id', title: 'ID' },
  { data: 'name', title: 'Name' },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item => M4CTable.actionsHTML(item, 'report', actions(item)),
  },
];

const ReportTable = new M4CTable({
  columns: columns,
  table: 'reports',
  title: 'Reports',
  callbacks: {
    createdRow: (row, data) => {
      if (data.published !== 1) {
        $(row).addClass('unpublished');
        $(row)
          .find('[data-action="template-toggle-publish"]')
          .removeClass('btn-primary')
          .addClass('btn-warning');
      } else {
        $(row)
          .find('[data-action="template-toggle-publish"]')
          .addClass('btn-primary')
          .removeClass('btn-warning');
      }
      if (data.tag_count === 0) {
        $(row).addClass('untagged');
      }
      if (data.type === 'FILE') {
        $(row).addClass('file-type');
      }
    },
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
    initComplete: s => {
      s.oInstance.api().draw();
    },
  },
});

export { ReportTable };
