export const dualRing = () =>
  `<div class="m4c-loader-anim m4c-dual-ring"></div>`;
export const ripple = () =>
  `<div class="m4c-loader-anim m4c-ripple"><div></div><div></div></div>`;
export const dotCircle = () =>
  `<div class="m4c-loader-anim m4c-dot-circle"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;
export const roller = () =>
  `<div class="m4c-loader-anim m4c-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;
export const spinner = () =>
  `<div class="m4c-loader-anim m4c-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>`;
export const ring = () =>
  `<div class="m4c-loader-anim m4c-ring"><div></div><div></div><div></div><div></div></div>`;
export const ellipsis = () =>
  `<div class="m4c-loader-anim m4c-ellipsis"><div></div><div></div><div></div><div></div></div>`;
export const bars = () =>
  `<div class="m4c-loader-anim m4c-bars"><div></div><div></div><div></div></div>`;
export const ball = () =>
  `<div class="m4c-loader-anim m4c-ball"><div></div><div></div><div></div></div>`;
