/* global $ */

import Loader from './Loader.js';
import * as animations from '../animations.js';
import { v4 as UUID } from 'uuid';

class ButtonLoader extends Loader {
  constructor({
    start,
    stop,
    anim = 'spinner',
    scale,
    targetSelector,
    messageSelector,
    scope,
  } = {}) {
    super({ start, stop, anim, scale, messageSelector, scope });
    this.targetSelector = targetSelector;
    this.id = UUID();
    if (!this.start) {
      const animation = animations[anim];
      if (!animation || typeof animation !== 'function') {
        throw new Error(`Bad animation name: ${this.anim}`);
      }
      this.start = () => {
        this.replacedButton = $(this.targetSelector);
        this.height = $(this.targetSelector).outerHeight();
        this.width = $(this.targetSelector).outerWidth();
        const background = $(this.targetSelector).css('background');
        const borderRadius = $(this.targetSelector).css('border-radius');
        const border = $(this.targetSelector).css('border');
        const padding = $(this.targetSelector).css('padding');
        $(this.targetSelector).replaceWith(`
          <div id="${this.id}" class="m4c-button-loader ${this.scope}">
              <div class="m4c-button-loader-wrapper">
                ${animation()}
              </div>
              <div id="m4c-button-loader-message" class="m4c-button-loader-message"></div>
          </div>
        `);

        $(`#${this.id}`).css({
          height: this.height,
          width: this.width,
          overflow: 'hidden',
          border,
          background,
          borderRadius,
          padding,
        });

        if (this.scaleMultiplier) {
          window.setTimeout(() => {
            this.scale();
          }, 250);
        }
      };
    }

    if (!this.stop) {
      this.stop = () => {
        $(`#${this.id}`).replaceWith(this.replacedButton);
      };
    }
  }
}

export { ButtonLoader };
