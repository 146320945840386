/* global $, MatgenGlobal */

import Loader from './Loader.js';
import * as animations from '../animations.js';

class FullScreenLoader extends Loader {
  constructor({
    start,
    stop,
    anim = 'spinner',
    scale,
    bgfxSelector,
    messageSelector,
    scope,
    transition = 'grayscale 2s, invert 2s, blur 2s',
    filter = 'grayscale(100%) invert(1) blur(3px)',
  } = {}) {
    super({ start, stop, anim, scale, messageSelector, scope });
    this.transition = transition;
    this.filter = filter;
    if (!this.start) {
      const animation = animations[this.anim];
      if (!animation || typeof animation !== 'function') {
        throw new Error(`Bad animation name: ${this.anim}`);
      }
      this.start = ({ message, keepLoading, group } = {}) => {
        console.error('starting loader:', message, this, group);
        this.messageContent = message;
        this.keepLoading = keepLoading;
        if ($('#m4c-fullscreen-loader').length === 0) {
          if (bgfxSelector) {
            $(bgfxSelector).css({
              transition: this.transition,
              filter: this.filter,
            });
          }
          $('body').append(`
            <div id="m4c-fullscreen-loader" class="${this.scope}">
              <div class="m4c-loader-overlay m4c-loading"></div>
              <div class="m4c-fullscreen-loader-wrapper">
                <div class="m4c-loader-content">
                  ${animation()}
                  <div id="${this.messageSelector.replace(
                    /(#|\.)/g,
                    ''
                  )}" class="m4c-fullscreen-loader-message"></div>
                </div>
              </div>
            </div>
          `);

          if (this.scaleMultiplier) {
            window.setTimeout(() => {
              this.scale();
            }, 250);
          }
          this.message(message);
        } else {
          this.message(message);
        }
      };
    }

    if (!this.stop) {
      this.stop = () => {
        if (!MatgenGlobal.keepLoading && !this.keepLoading) {
          console.error('stopping loader:', this.messageContent, this);

          $(bgfxSelector).css({
            filter: 'initial',
          });
          $('#m4c-fullscreen-loader').remove();
        }
      };
    }
  }
}

export { FullScreenLoader };
