/* global $ */

import Loader from './Loader.js';
import * as animations from '../animations.js';
import { v4 as UUID } from 'uuid';

class ElementLoader extends Loader {
  constructor({
    start,
    stop,
    anim = 'spinner',
    scale,
    targetSelector,
    bgColor,
    messageSelector,
    scope,
  } = {}) {
    super({ start, stop, anim, scale, messageSelector, scope });
    this.bgColor = bgColor;
    this.targetSelector = targetSelector;
    this.id = UUID();
    if (!this.start) {
      const animation = animations[anim];
      if (!animation || typeof animation !== 'function') {
        throw new Error(`Bad animation name: ${this.anim}`);
      }
      this.start = () => {
        if ($(this.targetSelector).css('position') === 'static') {
          this.targetSelectorPreviousPosition = $(this.targetSelector).css(
            'position'
          );
          $(this.targetSelector).css('position', 'relative');
        }
        /*$(this.targetSelector).css(
          'height',
          `${$(this.targetSelector).height()}px`
        );*/
        $(this.targetSelector).append(`
          <div id="${this.id}" class="m4c-element-loader ${this.scope}">
            <div class="m4c-element-loader-wrapper">
              ${animation()}
              <div id="m4c-element-loader-message" class="m4c-element-loader-message"></div>
            </div>
          </div>
        `);
        $(`#${this.id}`).css('background-color', this.bgColor);
        const borderWidth = $(this.targetSelector).css('border-width');
        $(`#${this.id}`).css(
          'height',
          `${$(this.targetSelector).outerHeight() - borderWidth * 2}px`
        );

        if (this.scaleMultiplier) {
          window.setTimeout(() => {
            this.scale();
          }, 250);
        }
      };
    }

    if (!this.stop) {
      this.stop = () => {
        $(this.targetSelector).css('height', 'inititial');
        $(`#${this.id}`).remove();
      };
    }
  }
}

export { ElementLoader };
