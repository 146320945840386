/* global M4CGlobal, MatgenGlobal, bootstrap, API_ENV, TENANT_ENV */

import { Loaders } from './m4c-loaders';
import { API, Auth, Storage } from 'aws-amplify';
import memoize from 'memoizee';
import profile from 'memoizee/profile';
import { v4 as UUID } from 'uuid';
import { MatgenData } from './core/api/matgen-data.js';
import { Router } from './core/router/router-navigo.js';
import M4CAmplify from './m4c-amplify/M4CAmplify.js';
import M4CAmplifyUser from './m4c-amplify/M4CAmplifyUser.js';
import LoaderManager from './m4c-loader/M4CLoaderManager.js';
import { M4CModal } from './m4c-modal/M4CModal.js';
import { init as tableInit } from './m4c-table/table-events.js';
import * as Tables from './m4c-table/tables';
import { init as commonInit } from './matgen-ui/client/events/common-events.js';
import { init as editorInit } from './matgen-ui/client/events/editor-events.js';
import { emit } from './matgen-ui/common/helpers.js';
import MatgenUIFunctions from './matgen-ui/ui-functions.js';
import { UI } from './matgen-ui/ui.js';

window.profile = profile;

const InitMatgenCore = () => {
  if (typeof MatgenGlobal === 'undefined') {
    window.MatgenGlobal = {};
  }
  if (typeof M4CGlobal === 'undefined') {
    window.M4CGlobal = {};
  }

  //const MatgenGlobal = (window.MatgenGlobal = {});
  //const M4CGlobal = (window.M4CGlobal = {});

  const api_env = API_ENV === 'production' ? 'prod' : 'dev';
  const tenant_env = TENANT_ENV ? TENANT_ENV : 'm4c';

  M4CGlobal.Matgen = MatgenGlobal;

  if (!MatgenGlobal.Amplify) {
    MatgenGlobal.Amplify = {
      Auth,
      Storage,
      API,
    };
  }

  MatgenGlobal.api_env = api_env;
  MatgenGlobal.tenant_env = tenant_env;
  MatgenGlobal.UI = UI;

  MatgenGlobal.LoaderManager = new LoaderManager();

  const fullScreenLoader = new Loaders.FullScreenLoader({
    anim: 'dualRing',
    bgfxSelector: '#matgen-whole',
    messageSelector: '#m4c-fullscreen-loader-message',
    scope: 'matgen-fullscreen-loader',
    transition: 'grayscale 2s, brightness 2s, blur 2s',
    filter: 'grayscale(100%) brightness(.95) blur(3px)',
  });

  const MatgenPageLoader = MatgenGlobal.LoaderManager.addLoader({
    loader: fullScreenLoader,
    id: 'matgen-loader',
    //debug: true,
  });

  MatgenGlobal.MatgenPageLoader = MatgenPageLoader;

  MatgenGlobal.emit = emit;
  MatgenGlobal.M4CModal = M4CModal;
  MatgenGlobal.Router = new Router();
  MatgenGlobal.Data = new MatgenData();
  MatgenGlobal.UUID = UUID;
  MatgenGlobal.Tables = Tables;
  MatgenGlobal.MatgenUIFunctions = MatgenUIFunctions;
  MatgenGlobal.MatgenUIFunctions = MatgenUIFunctions;
  MatgenGlobal.AuthUserModule = M4CAmplifyUser;
  MatgenGlobal.CurrentAPIRequests = [];
  MatgenGlobal.CurrentS3Requests = [];
  MatgenGlobal.DynamicForms = {};

  MatgenGlobal.CommonInit = commonInit;
  MatgenGlobal.EditorInit = editorInit;
  MatgenGlobal.TableInit = tableInit;

  MatgenGlobal.hideTooltips = () => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    [...tooltipTriggerList].map(tooltipTriggerEl => {
      const tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
      if (tooltip) {
        tooltip.hide();
      }
    });
  };

  MatgenGlobal.init = async ({
    token = false,
    identity_id = false,
    containerId = 'matgen-container',
    controllerTargetSelector = '#matgen-controller',
    sidebarTargetSelector = 'main',
    rootPage = 'index.html',
    gridColor = '#aaa',
    borderScale = 4,
    borderColor = '#FB1330',
    borderColorDarkened = '#c71f1f',
    editingBorderColor = '#00a9ff',
    scalingTargetId = 'matgen-scale-container-init-default',
    expiresIn = 60,
  } = {}) => {
    if (!MatgenGlobal.MatgenInitialized) {
      /*const api_env = API_ENV === 'production' ? 'prod' : 'dev';
      const tenant_env = TENANT_ENV ? TENANT_ENV : 'm4c';
      const tenantEnvironment = await import(
        `./.config/tenants/${TENANT_ENV}.js`
      );

      let AMPLIFY_VARS = MatgenGlobal.amplify_config_vars;
      if (!AMPLIFY_VARS) {
        AMPLIFY_VARS = tenantEnvironment.default[tenant_env][api_env];
      }*/

      if (!MatgenGlobal.AMPLIFY_VARS) {
        throw new Error('Amplify module: Missing configuration.');
      }

      //MatgenGlobal.AMPLIFY_VARS = AMPLIFY_VARS;
      if (!MatgenGlobal.base_config) {
        MatgenGlobal.base_config = MatgenGlobal.AMPLIFY_VARS;
      }

      MatgenGlobal.MatgenAmplify = new M4CAmplify({
        AMPLIFY_VARS: MatgenGlobal.AMPLIFY_VARS,
        //cb: () => {},
      });

      MatgenGlobal.ContainerId = containerId;
      MatgenGlobal.ControllerTargetSelector = controllerTargetSelector;
      MatgenGlobal.SidebarTargetSelector = sidebarTargetSelector;
      MatgenGlobal.ScalingTargetId = scalingTargetId;
      if (!MatgenGlobal.RootPage) {
        MatgenGlobal.RootPage = rootPage;
      }
      MatgenGlobal.GridColor = gridColor;
      MatgenGlobal.BorderScale = borderScale;
      MatgenGlobal.BorderColor = borderColor;
      MatgenGlobal.BorderColorDarkened = borderColorDarkened;
      MatgenGlobal.EditingBorderColor = editingBorderColor;
      MatgenGlobal.ExpiresIn = expiresIn;
      MatgenGlobal.StateId = 0;
      MatgenGlobal.PreviousStateId = 0;

      M4CGlobal.SHOW_DUPE_COUNT = true;

      const memoizedUser = memoize(
        () => new M4CAmplifyUser(token, identity_id),
        {
          maxAge: 300000,
          promise: true,
        }
      );

      await memoizedUser();

      const user = MatgenGlobal.AuthUser
        ? await MatgenGlobal.AuthUser.getUser()
        : false;
      if (!user) {
        MatgenGlobal.AuthUser = new MatgenGlobal.AuthUserModule();
        await MatgenGlobal.AuthUser.getUser();
      }

      MatgenGlobal.CommonInit();
      MatgenGlobal.EditorInit();
      MatgenGlobal.TableInit();

      emit({
        event: 'matgen-ready',
      });
      MatgenGlobal.MatgenInitialized;
    }
  };
};

export default InitMatgenCore;
