/* global $ */

class Loader {
  constructor({
    start,
    stop,
    complete,
    anim = 'spinner',
    scale = 1,
    messageSelector = false,
    scope = '',
  } = {}) {
    if (start && typeof start === 'function') {
      this.start = start;
    }
    if (stop && typeof stop === 'function') {
      this.stop = stop;
    }
    if (complete && typeof complete === 'function') {
      this.complete = complete;
    }
    this.scope = scope;
    this.anim = anim;
    this.scaleMultiplier = parseFloat(scale);
    this.messageSelector = messageSelector;
  }
  scale() {
    if (
      !this.scaleMultiplier ||
      this.scaleMultiplier === 1 ||
      this.scaleMultiplier === 0
    ) {
      return false;
    }
    let animation = this.anim;
    if (this.anim === 'dotCircle') {
      animation = 'dot-circle';
    }
    if (this.anim === 'dualRing') {
      animation = 'dual-ring';
    }
    $('.m4c-loader-anim').width(
      $('.m4c-loader-anim').width() * this.scaleMultiplier
    );
    $('.m4c-loader-anim').height(
      $('.m4c-loader-anim').height() * this.scaleMultiplier
    );
    $(`.m4c-${animation}`).css('transform', `scale(${this.scaleMultiplier})`);
  }
  message(message) {
    if (this.messageSelector) {
      if (message && message !== '') {
        this.messageContent = message;
        const lines = message.split('\n');
        const html = `<p>${lines
          .filter(l => l !== '')
          .join('...</p><p>')}...</p>`;
        $(this.messageSelector)
          .empty()
          .html(html);
      }
    }
  }
}

export default Loader;
