/* global MatgenGlobal */

class OptionsModal {
  init(opts, default_option_id) {
    this.default_option_id = default_option_id;
    this.opts = opts;
    this.markup = this.buildHTML();
  }

  buildHTML() {
    return this.container(
      this.opts.map(o => this.option(o, MatgenGlobal.AuthUser)).join('')
    );
  }

  container(content) {
    return `
    <div>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <span class="nav-link active" aria-current="page">All Options</span>
          </li>
          ${
            MatgenGlobal.AuthUser &&
            MatgenGlobal.AuthUser.getUserRole() === 'user'
              ? `
          <li class="nav-item">
            <span class="nav-link favorites">Favorited Options</span>
          </li>
            `
              : ''
          }
        </ul>
      </div>
      <div id="options-container">
        ${content}
      </div>
    `;
  }
  option(o) {
    let src = o.loadedImage.src;
    if (
      MatgenGlobal.AuthUser &&
      MatgenGlobal.AuthUser.getUserRole() === 'super'
    ) {
      src += `?v=${Math.floor(Math.random() * 10000)}`;
    }
    let inLocalStorage = localStorage.getItem(`favoritedOptions`);
    inLocalStorage = inLocalStorage ? JSON.parse(inLocalStorage) : [];
    // check if any array have option_id equals o.id
    inLocalStorage = inLocalStorage.some(
      option =>
        option.option_id === o.id && option.component_id === o.componentId
    );

    return `
    ${
      MatgenGlobal.AuthUser &&
      (MatgenGlobal.AuthUser.getUserRole() === 'super' ||
        MatgenGlobal.AuthUser.getUserRole() === 'admin')
        ? `<div class="admin-option-container">`
        : ''
    }

      <div
        class="option-wrapper m4c-matgen ${
          (o.favorited || inLocalStorage) &&
          MatgenGlobal.AuthUser.getUserRole() === 'user'
            ? 'favorited'
            : ''
        }"
        data-option-id="${o.id}"
        data-component-id="${o.componentId}"
        tabindex="0"
      />
        ${
          MatgenGlobal.AuthUser.getUserRole() === 'user'
            ? `<div class="tooltip ${
                o.favorited || inLocalStorage ? 'favorite-tooltip' : ''
              }" data-component-id="${o.componentId}" data-option-id="${o.id}">
          <i class="favorited-heart fa fa-heart">test</i>
          <i class="not-favorited-heart fa fa-heart-o">test</i>
        </div>`
            : ''
        }
        <img class="img-fluid img-responsive ${
          !o.enabled ? 'disabled-option' : ''
        }" data-src="${src}" />
      </div>
      ${
        MatgenGlobal.AuthUser &&
        (MatgenGlobal.AuthUser.getUserRole() === 'super' ||
          MatgenGlobal.AuthUser.getUserRole() === 'admin')
          ? `<div class="option-actions">
          <a href="#" data-bs-toggle="tooltip" data-placement="auto" title="Delete option" class="option-action"><i class="fas fa-trash fa-xs"></i></a>
          ${
            o.enabled
              ? `<a href="#" data-bs-toggle="tooltip" data-placement="auto" title="Disable option" class="option-action"><i class="fas fa-eye-slash fa-xs"></i></a>`
              : `<a href="#" data-bs-toggle="tooltip" data-placement="auto" title="Enable option" class="option-action"><i class="fas fa-eye fa-xs"></i></a>`
          }
          ${
            o.id === this.default_option_id
              ? `
              <a href="#" data-bs-toggle="tooltip" data-placement="auto" title="Default option" class="option-action">
                <i class="fas fa-check-square fa-xs"></i></a>
              </a>`
              : `
              <a href="#" data-bs-toggle="tooltip" data-placement="auto" title="Set as default" class="option-action">
                <i class="far fa-square fa-xs"></i>
              </a>`
          }
        </div>`
          : ''
      }
      ${
        MatgenGlobal.AuthUser &&
        (MatgenGlobal.AuthUser.getUserRole() === 'super' ||
          MatgenGlobal.AuthUser.getUserRole() === 'admin')
          ? `</div>`
          : ''
      }
    `;
  }
}

export { OptionsModal };
