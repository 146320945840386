import { Input } from './Input.js';

class TextInput extends Input {
  constructor() {
    super(...arguments);
  }
  getHTML() {
    return `
      <div class="row">
        <label for="${this.id}" class="col-5 col-form-label">${
      this.label
    }</label>
        <div class="col-7">
          <input
            type="${this.type}"
            id="${this.id}"
            name="${this.id}"
            class="form-control ${this.classes ? this.classes : ''}${
      this.maxChars ? ' char-limited' : ''
    }"
            placeholder="${this.label}"
            ${this.maxChars ? `data-max-chars="${this.maxChars}"` : ''}
            ${this.maxChars ? `maxlength="${this.maxChars}"` : ''}
            ${this.required ? 'required' : ''}
            ${
              this.type === 'tel'
                ? 'pattern="[^a-zA-Z0-9_\n]{0,2}[0-9]{0,3}[^a-zA-Z0-9_\n]{0,2}[0-9]{0,3}[^a-zA-Z0-9_\n]{0,2}[0-9]{0,3}[^a-zA-Z0-9_\n]{0,2}[0-9]{0,4}\n?"'
                : ''
            }
            ${this.autofocus ? 'autofocus' : ''}
            ${
              this.value || (this.type === 'number' && this.value === 0)
                ? `value="${this.value}"`
                : ''
            }
          />
          ${
            this.maxChars
              ? `<div class="example-char-wrapper">
            <!--<a href="#" class="view-example" data-id="${
              this.id
            }">View Example</a>-->
            <p class="char-limit">Character limit: <span class="character-count" data-id="${
              this.id
            }">0</span>/${this.maxChars ? this.maxChars : '256'}</p>
            </div>`
              : ''
          }
        </div>
        ${this.helpText ? `<div class="form-text">${this.helpText}</div>` : ''}
      </div>
      `;
  }
}

export { TextInput };
