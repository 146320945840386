/* global $, M4CGlobal, MatgenGlobal, Buffer, bootstrap */

import { v4 as UUID } from 'uuid';
import { fabric } from 'fabric';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import merge from 'deepmerge';
import * as MatgenForms from '../m4c-form/forms';
import * as MatgenFormsComponents from '../m4c-form/components';
import { emit } from '../matgen-ui/common/helpers.js';
import { MatgenPDF } from '../matgen-ui/components/MatgenPDF.js';
import { toggleIconChooser } from '../matgen-ui/common/icon-chooser.js';
import { Form, TextInput, TextAreaInput } from '../m4c-form/form/index.js';
import { MatgenEditor } from '../core/matgen-editor.js';
import { Uploader } from '../matgen-ui/components/Uploader.js';
import M4CRichTextEditor from './components/M4CRichTextEditor.js';

export function initIconPicker() {
  MatgenGlobal.M4CModal.show({
    id: 'icon-picker-modal',
    title: 'Select Icon',
    content: '<div id="fa-icon-chooser-div"></div>',
    buttons: [
      {
        id: 'icon-picker-submit',
        classname: 'primary btn btn-primary',
        label: 'Select',
      },
    ],
  });
  toggleIconChooser();
  $(document).off('hide.bs.modal', `#icon-picker-modal`);
  $(document).on('hide.bs.modal', `#icon-picker-modal`, () => {
    toggleIconChooser();
  });
}

function replaceIds(
  json,
  { id, newTemplateId, pageIdMap, componentIdMap, optionIdMap }
) {
  let json2 = json;
  json2 = json2.replaceAll(id, newTemplateId);
  const pageKeys = Object.keys(pageIdMap);
  for (let j = 0; j < pageKeys.length; j++) {
    json2 = json2.replaceAll(pageKeys[j], pageIdMap[pageKeys[j]]);
  }
  const componentKeys = Object.keys(componentIdMap);

  for (let j = 0; j < componentKeys.length; j++) {
    json2 = json2.replaceAll(
      componentKeys[j],
      componentIdMap[componentKeys[j]]
    );
  }
  const optionKeys = Object.keys(optionIdMap);
  for (let j = 0; j < optionKeys.length; j++) {
    json2 = json2.replaceAll(optionKeys[j], optionIdMap[optionKeys[j]]);
  }
  return json2;
}

export default class MatgenUIFunctions {
  static async handleMaterialDownload(id) {
    let non508 = false;
    if ($('#main-loader-wrapper').length !== 0) {
      const material = await MatgenGlobal.Data.getMaterial(id);
      const template = await MatgenGlobal.Data.getTemplate(
        material.template_id
      );

      if (template.type !== 'FILE' && template.type !== 'VIDEO') {
        const pages = await MatgenGlobal.Data.getPages(material.template_id);
        const json = await MatgenGlobal.Data.getMaterialPageFile(
          id,
          pages[0].id
        );
        const studyid = json.studyid;
        if (studyid) {
          await MatgenGlobal.generator.getMicrositeData(studyid);

          let languageAnswerIndex = MatgenGlobal.MicrositeData[0].questions
            .find(q => q.text === 'Select a language')
            .answers.findIndex(a => a.userAnswer === true);
          if (languageAnswerIndex === -1) {
            languageAnswerIndex = 0;
          }
          non508 = [2, 4].includes(languageAnswerIndex);
        } else if (
          template.name.includes('(Chinese)') ||
          template.name.includes('(Hindi)')
        ) {
          non508 = true;
        }
      }
      await MatgenGlobal.MatgenUIFunctions.downloadMaterial(id, true, non508);
    } else {
      const material = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading material',
        group: 'download-get-material',
        promise: MatgenGlobal.Data.getMaterial(id),
      });
      const template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template',
        group: 'download-get-template',
        promise: MatgenGlobal.Data.getTemplate(material.template_id),
      });

      if (template.type !== 'FILE' && template.type !== 'VIDEO') {
        const pages = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading pages',
          promise: MatgenGlobal.Data.getPages(material.template_id),
        });
        pages.sort((a, b) => a.number - b.number);
        const json = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading page JSON',
          promise: MatgenGlobal.Data.getMaterialPageFile(id, pages[0].id),
        });
        const studyid = json.studyid;
        if (studyid) {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading study data',
            promise: MatgenGlobal.generator.getMicrositeData(studyid),
          });
          let languageAnswerIndex = MatgenGlobal.MicrositeData[0].questions
            .find(q => q.text === 'Select a language')
            .answers.findIndex(a => a.userAnswer === true);
          if (languageAnswerIndex === -1) {
            languageAnswerIndex = 0;
          }
          non508 = [2, 4].includes(languageAnswerIndex);
        } else if (
          template.name.includes('(Chinese)') ||
          template.name.includes('(Hindi)')
        ) {
          non508 = true;
        }
      }

      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Downloading material',
        group: 'download-download',
        promise: MatgenGlobal.MatgenUIFunctions.downloadMaterial(
          id,
          true,
          non508
        ),
      });
    }
  }

  static async loadPagePreview(
    template,
    pageId,
    materialId,
    type = 'material',
    temp = false,
    keepLoading = false
  ) {
    let json;
    try {
      if (type === 'template') {
        json = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading template JSON',
          promise: MatgenGlobal.Data.getTemplateFile(
            pageId,
            template.tenant_id
          ),
        });
      } else {
        json = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading template JSON',
          promise: MatgenGlobal.Data.getMaterialPageFile(materialId, pageId),
        });
      }
      if (json.error === true) {
        return false;
      }
    } catch (e) {
      console.error(e);
      return MatgenPDF.handlePDFError();
    }

    let editDiv;
    if ($('#matgen-edit-canvas').length > 0) {
      $('#matgen-edit-canvas').empty();
      editDiv = $('#matgen-edit-canvas');
    } else {
      editDiv = $(
        `<div id="matgen-edit-canvas" class="matgen-hidden-canvas"/>`
      );
      $('body').append(editDiv);
    }

    let ed = MatgenGlobal.editor;
    if (temp) {
      ed = MatgenGlobal.TempEditor;
    }

    const containerId = `matgen-scale-container-${UUID()}`;
    MatgenGlobal.containerSelector = `.matgen.scale.container`;

    ed = new MatgenEditor({
      id: `${template.id}-tmp`,
      pageId: pageId,
      containerId,
      templateId: template.id,
      width: template.width,
      height: template.height,
    });

    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading editor',
      promise: new Promise((resolve, reject) => {
        ed.load({
          json,
          template,
          targetSelector: '#matgen-edit-canvas',
          canvasContainerId: containerId,
          cb: async () => {
            if (type === 'template') {
              try {
                await MatgenGlobal.MatgenPageLoader.start({
                  message: 'Loading default options',
                  promise: ed.loadDefaults(template.id),
                });
                resolve(this);
              } catch (e) {
                console.error(e);
                reject(e);
              }
            } else {
              try {
                await MatgenGlobal.MatgenPageLoader.start({
                  message: 'Loading selected options',
                  promise: ed.loadSelectedOptions(materialId),
                });
                resolve(this);
              } catch (e) {
                console.error(e);
                reject(e);
              }
            }
            if (
              MatgenGlobal.editorCallback &&
              typeof MatgenGlobal.editorCallback === 'function'
            ) {
              MatgenGlobal.editorCallback();
            }
            resolve(ed);
          },
          keepLoading,
        });
      }),
    });
    editDiv.remove();
    return ed;
  }

  static async createOption(componentId) {
    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    const componentObjects = objects.filter(o => o.componentId === componentId);
    if (componentObjects.length < 1) {
      console.error('Objects not found for component id:', componentId);
    } else {
      let saveObj = componentObjects[0];
      if (componentObjects.length > 1) {
        const group = new fabric.Group(componentObjects);

        group.componentId = componentId;

        componentObjects.forEach(obj => {
          MatgenGlobal.editor.cur().fabric.remove(obj);
        });

        MatgenGlobal.editor.cur().fabric.add(group);
        group.setCoords();
        MatgenGlobal.editor.cur().fabric.renderAll();
        saveObj = group;
      }
      saveObj.set('id', UUID());
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving option',
        promise: MatgenGlobal.UI.saveOption(saveObj),
      });
    }
  }

  static getCanvasDataURL(fileType = null, target = false) {
    if (target === false) {
      target = MatgenGlobal.editor;
    }
    const c = target.cur();

    const origwidth = c.fabric.getWidth();
    const origheight = c.fabric.getHeight();
    const origzoom = c.fabric.getZoom();

    const width = target.width;
    const height = target.height;

    c.fabric.setWidth(width);
    c.fabric.setHeight(height);

    c.fabric.setDimensions({
      width: c.fabric.getWidth(),
      height: c.fabric.getHeight(),
    });
    c.fabric.setZoom(1);
    c.fabric.renderAll();

    const durl = c.fabric.toDataURL({ format: fileType, multiplier: 1.0 });

    c.fabric.setWidth(origwidth);
    c.fabric.setHeight(origheight);

    c.fabric.setDimensions({
      width: c.fabric.getWidth(),
      height: c.fabric.getHeight(),
    });
    c.fabric.setZoom(origzoom);
    c.fabric.renderAll();

    return durl;
  }

  static async downloadMaterial(id, hideSidebar = false, non508 = false) {
    const material = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading material',
      promise: MatgenGlobal.Data.getMaterial(id),
    });
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(material.template_id),
    });

    emit({
      event: 'matgen-material-download',
      detail: { template, material },
    });

    const tenant = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading tenant',
      promise: MatgenGlobal.Data.getTenant(template.tenant_id),
    });
    if (
      template.type === 'PDF' &&
      !MatgenGlobal.Suppress508 &&
      tenant.is508 === 1 &&
      !non508
    ) {
      MatgenUIFunctions.downloadMaterial508(
        template,
        id,
        hideSidebar,
        material
      );
    } else {
      MatgenUIFunctions.downloadMaterialSimple(
        template,
        material,
        id,
        hideSidebar
      );
    }
  }

  static async downloadMaterialSimple(
    template,
    material,
    id,
    hideSidebar = false
  ) {
    if (hideSidebar === true) {
      MatgenGlobal.hideSidebar = true;
    }
    MatgenGlobal.generating = true;
    const pages = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading pages',
      promise: MatgenGlobal.Data.getPages(material.template_id),
    });
    if (template.type === 'PDF') {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Generating simple PDF',
        promise: MatgenPDF.createPDF(
          template,
          material,
          pages,
          id,
          'material',
          true
        ),
      });
    } else if (template.type === 'IMAGE') {
      MatgenGlobal.UI.imgSelect(async fileType => {
        $('#image-select-modal').modal('hide');
        MatgenGlobal.TempEditor = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading editor',
          group: 'download-generating-image',
          promise: MatgenUIFunctions.loadPagePreview(
            template,
            pages[0].id,
            id,
            'material',
            true,
            true
          ),
        });

        const durl = MatgenUIFunctions.getCanvasDataURL(
          fileType,
          MatgenGlobal.TempEditor
        );

        MatgenGlobal.UI.downloadResource({ file: durl, name: template.name });
        if (MatgenGlobal.Tipsheet) {
          MatgenGlobal.UI.downloadResource(MatgenGlobal.Tipsheet);
        }

        if (hideSidebar === true) {
          delete MatgenGlobal.hideSidebar;
        }
        delete MatgenGlobal.generating;
        if ($('#matgen-edit-canvas').length > 0) {
          $('#matgen-edit-canvas').remove();
        }
        delete MatgenGlobal.TempEditor;
      });
    } else if (template.type === 'FILE' || template.type === 'VIDEO') {
      MatgenUIFunctions.downloadFileTemplateOrMaterial(
        template.id,
        template.tenant_id,
        template.file_ext
      );
    }
    /*emit({
      event: 'matgen-material-download',
      detail: { template, material },
    });*/
  }

  static async generate508PDF(
    id,
    hideSidebar = false,
    template = false,
    material = false,
    type = 'material',
    pdfRender = false
  ) {
    let template_id = id;
    if (template) {
      template_id = template.id;
    }
    MatgenGlobal.SuppressStopLoading = true;
    MatgenGlobal.generating = true;
    if (template.type !== 'PDF') {
      console.error('Bad template type:', template);
      return false;
    }
    if (hideSidebar === true) {
      MatgenGlobal.hideSidebar = true;
    }

    let pageData = [
      {
        id: 'dummy',
      },
    ];

    if (pdfRender !== true) {
      pageData = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading pages',
        promise: MatgenGlobal.Data.getPages(template_id),
      });
      pageData.sort((a, b) => a.number - b.number);
    }
    try {
      await MatgenPDF.createPDF(template, material, pageData, id, type, false);
    } catch (e) {
      if (e.message === 'Content overflow in PDF') {
        MatgenGlobal.UI.handleError(
          'Content overflow',
          'One or more items has overflown the page. Check your content and try again.'
        );
        return false;
      }
    }

    if (hideSidebar === true) {
      delete MatgenGlobal.hideSidebar;
    }
  }

  static async downloadMaterial508(
    template,
    id,
    hideSidebar = false,
    material
  ) {
    MatgenUIFunctions.generate508PDF(id, hideSidebar, template, material);
  }

  static async downloadFileTemplateOrMaterial(id, tenant_id, file_ext) {
    const url = MatgenGlobal.Data.getTemplateFileURL(id, tenant_id, file_ext);
    delete MatgenGlobal.generating;
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    MatgenGlobal.emit({
      event: 'download-pdf-generated-modal-closed',
    });
  }

  static async downloadTemplate(
    id,
    hideSidebar = false,
    pdfRender = false,
    non508 = false
  ) {
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(id),
    });

    MatgenGlobal.hideSidebar = hideSidebar;
    const tenant = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading tenant',
      promise: MatgenGlobal.Data.getTenant(template.tenant_id),
    });
    if (
      template.type === 'PDF' &&
      !MatgenGlobal.Suppress508 &&
      tenant.is508 === 1 &&
      !non508
    ) {
      MatgenUIFunctions.downloadTemplate508(
        id,
        hideSidebar,
        template,
        pdfRender
      );
    } else {
      MatgenUIFunctions.downloadTemplateSimple(id, hideSidebar);
    }
  }

  static async downloadReport(id) {
    const report = await MatgenGlobal.Data.getReport(id);
    let output = await MatgenGlobal.Data.runReport(id);

    if (Array.isArray(output)) {
      if (output.length === 0) {
        return '';
      }

      // Extract the keys (header)
      const keys = Object.keys(output[0]);

      // Create the header row
      const header = keys.join(',');

      // Create the data rows
      const rows = output.map(obj =>
        keys.map(key => JSON.stringify(obj[key] || '')).join(',')
      );

      // Combine header and rows
      output = [header, ...rows].join('\n');

      console.log(output);
      // output = `Results,\n${output.join(',\n')}`;
    }

    const filename = `${report.name
      .toLowerCase()
      .replace(/\s+/g, '-')}-${new Date().toISOString()}.csv`;

    // Convert array to CSV string
    const csvContent = output;

    // Create a blob from the CSV string
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Feature detection
      // Create a URL for the blob and set it as the href attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);

      // Append the link to the body
      document.body.appendChild(link);

      // Simulate a click to trigger the download
      link.click();

      // Clean up and remove the link
      document.body.removeChild(link);
    }
  }

  static async downloadTemplateSimple(id, hideSidebar) {
    MatgenGlobal.generating = true;
    if (hideSidebar === true) {
      MatgenGlobal.hideSidebar = true;
    }
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(id),
    });
    if (template.type === 'FILE' || template.type === 'VIDEO') {
      MatgenUIFunctions.downloadFileTemplateOrMaterial(
        template.id,
        template.tenant_id,
        template.file_ext,
        'template'
      );
    } else {
      const pages = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template pages',
        promise: MatgenGlobal.Data.getPages(template.id),
      });
      pages.sort((a, b) => a.number - b.number);
      if (template.type === 'PDF') {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Generating simple PDF',
          promise: MatgenPDF.createPDF(
            template,
            false,
            pages,
            id,
            'template',
            true
          ),
        });
      } else if (template.type === 'IMAGE') {
        MatgenGlobal.UI.imgSelect(async fileType => {
          $('#image-select-modal').modal('hide');
          MatgenGlobal.TempEditor = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading editor',
            group: 'download-generating-image',
            promise: MatgenUIFunctions.loadPagePreview(
              template,
              pages[0].id,
              id,
              'template',
              true,
              true
            ),
          });

          const durl = MatgenUIFunctions.getCanvasDataURL(
            fileType,
            MatgenGlobal.TempEditor
          );

          MatgenGlobal.UI.downloadResource({ file: durl, name: template.name });
          if (MatgenGlobal.Tipsheet) {
            MatgenGlobal.UI.downloadResource(MatgenGlobal.Tipsheet);
          }

          if (hideSidebar === true) {
            delete MatgenGlobal.hideSidebar;
          }
          delete MatgenGlobal.generating;
          delete MatgenGlobal.TempEditor;
        });
      }
    }
  }

  static async downloadTemplate508(
    id,
    hideSidebar = false,
    template,
    pdfRender = false
  ) {
    MatgenUIFunctions.generate508PDF(
      id,
      hideSidebar,
      template,
      false,
      'template',
      pdfRender
    );
  }

  static compareNumbers(a, b) {
    return a - b || (a || Infinity) - (b || Infinity) || 0;
  }

  static readOrderSortOne(a, b) {
    return (
      MatgenUIFunctions.compareNumbers(a.ro1, b.ro1) ||
      MatgenUIFunctions.compareNumbers(a.ro2, b.ro2)
    );
  }

  static readOrderSort(a, b) {
    const roa = a.componentReadOrder ? a.componentReadOrder : a.readOrder;
    const rob = b.componentReadOrder ? b.componentReadOrder : b.readOrder;
    if (isNaN(parseInt(roa))) {
      return 1;
    } else if (isNaN(parseInt(rob))) {
      return -1;
    } else {
      return parseInt(roa) - parseInt(rob);
    }
  }

  static getReadOrder(o) {
    let ro1, ro2;
    const tag = o.pdfTag ? o.pdfTag : o.componentPdfTag;
    if (tag && tag !== 'ARTIFACT') {
      if (
        typeof o.componentReadOrder !== 'undefined' &&
        typeof o.readOrder === 'undefined'
      ) {
        ro1 = parseInt(o.componentReadOrder);
      }
      if (
        typeof o.componentReadOrder === 'undefined' &&
        typeof o.readOrder !== 'undefined'
      ) {
        ro1 = parseInt(o.readOrder);
      }
      if (
        typeof o.componentReadOrder !== 'undefined' &&
        typeof o.readOrder !== 'undefined'
      ) {
        ro1 = parseInt(o.componentReadOrder);
        ro2 = parseInt(o.readOrder);
      }
    } else {
      ro1 = parseInt(o.readOrder);
    }
    o.ro1 = ro1;
    o.ro2 = ro2;
    return o;
  }

  static async viewMaterial(id) {
    let material = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading material',
      promise: MatgenGlobal.Data.getMaterial(id),
    });
    if (material && Array.isArray(material)) {
      material = material[0];
    }
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(material.template_id),
    });
    if (['PDF', 'IMAGE'].includes(template.type)) {
      const pages = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading pages',
        promise: MatgenGlobal.Data.getPages(material.template_id),
      });
      const pageThumbs = pages
        .sort((a, b) => a.number - b.number)
        .map(p => p.id);
      const image = new Image();
      image.src = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading preview',
        promise: MatgenGlobal.Data.getMaterialPreviewURL(id, pageThumbs[0]),
      });

      image.onerror = () => {
        MatgenGlobal.UI.handleError(
          'Not Found',
          'We are unable to locate the preview image.'
        );
      };

      image.onload = () => {
        image.onload = null;
        const img = $(image.outerHTML);
        img.css({
          'max-width': image.width / 2,
          margin: 'auto',
        });
        img.attr('id', 'page-thumb');
        img.attr('alt', 'Preview of template');
        let content = img;
        if (pageThumbs.length > 1) {
          const wrapper = $('<div class="preview-thumb" id="preview-thumb" />');
          wrapper.append(img);
          wrapper.append(
            $(
              `
                ${
                  pageThumbs.length > 1
                    ? `
                  <div id="thumb-pager" class="text-center">
                  ${pageThumbs
                    .map(
                      (t, i) => `
                      ${i === 0 ? '' : ' - '}
                      <a href="#" data-id="${id}" data-page-id="${
                        pages[i].id
                      }" data-index="${i}" class="thumb-page">${i + 1}</a>
                      `
                    )
                    .join('')}
                  </div>
                  `
                    : ''
                }
                </div>`
            )
          );
          content = wrapper;
        }
        emit({
          event: 'matgen-material-preview',
          detail: { template, material },
        });
        MatgenGlobal.UI.alertModal(
          null,
          content[0].outerHTML,
          null,
          'preview-modal'
        );

        $('#page-thumb').on('error', e => {
          if (
            $(e.target).attr('src') !== '/assets/img/file-lines-regular.svg'
          ) {
            $(e.target).attr('src', '/assets/img/file-lines-regular.svg');
            $(e.target).addClass('missing-thumb');
          }
        });
      };
    } else if (template.type === 'FILE' || template.type === 'VIDEO') {
      if (template.preview_type === 'IMAGE') {
        const image = new Image();
        image.src = MatgenGlobal.Data.getTemplateFileURL(
          template.id,
          template.tenant_id,
          template.preview_image_ext
        );

        image.onload = () => {
          image.onload = null;
          const img = $(image.outerHTML);
          img.css({
            'max-width': image.width / 2,
            margin: 'auto',
          });
          img.attr('id', 'page-thumb');
          img.attr('alt', 'Preview of material');
          let content = img;

          const wrapper = $('<div class="preview-thumb" id="preview-thumb" />');
          wrapper.append(img);
          content = wrapper;

          MatgenGlobal.UI.alertModal(
            null,
            content[0].outerHTML,
            null,
            'preview-modal'
          );
        };
      } else if (template.preview_type === 'LINK') {
        delete MatgenGlobal.generating;
        window.open(template.preview_link);
      } else if (template.preview_type === 'SELF') {
        MatgenUIFunctions.downloadFileTemplateOrMaterial(
          template.id,
          template.tenant_id,
          template.file_ext
        );
      } else {
        console.error('Bad material type:', material, template);
        MatgenGlobal.UI.handleError(
          'Unknown material type',
          'The system does not recognize the given material type.'
        );
      }
    } else {
      console.error('Bad material type:', material, template);
      MatgenGlobal.UI.handleError(
        'Unknown material type',
        'The system does not recognize the given material type.'
      );
    }
  }

  static async editMaterialData(id, row) {
    let material = false;
    if (id) {
      material = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading material',
        promise: MatgenGlobal.Data.getMaterial(id),
      });
    }

    const hiddenFields = [];
    if (material && !material.error) {
      hiddenFields.push({ name: 'inputUserName', value: material.user_name });
    }

    MatgenUIFunctions.modalForm({
      prefix: 'material',
      inputs: [
        new TextInput({
          type: 'text',
          label: 'Name',
          dataId: 'name',
          id: 'inputName',
          required: true,
          autofocus: true,
        }),
      ],
      title: 'Edit Material',
      data: material ? material : false,
      options: { inline: false },
      actions: [
        {
          id: 'material-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Update',
        },
      ],
      hiddenFields,
      listeners: () => {
        $(document).off('click', '#material-form-submit');
        $(document).on('click', '#material-form-submit', () => {
          $('#material-form').submit();
        });

        $(document).off('submit', '#material-form');
        $(document).on('submit', '#material-form', async e => {
          e.preventDefault();

          if ($('#material-form')[0].checkValidity()) {
            try {
              await MatgenGlobal.MatgenPageLoader.start({
                message: 'Saving material',
                promise: MatgenGlobal.Data.saveMaterial(
                  {
                    name: $('#inputName').val(),
                    id: $('#material-data-id').val(),
                    user_name: $('#inputUserName').val(),
                  },
                  true
                ),
              });

              MatgenGlobal.Tables.MaterialTable.updateCellData(
                row,
                1,
                $('#inputName').val()
              );
            } catch (e) {
              console.error(e);
              MatgenGlobal.UI.handleError(
                'Error Saving Material',
                'There was an error saving your material.'
              );
            }
            $(`#material-form-modal`).modal('hide');
          } else {
            $(`#material-form`)[0].reportValidity();
          }
        });
      },
    });
  }

  static async deleteMaterial(id) {
    const response = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting material',
      promise: MatgenGlobal.Data.deleteMaterial(id),
    });
    if (response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem deleting the material.'
      );
      return false;
    }
    window.location.reload();
  }

  static async deleteMicrosite(id, row) {
    const response = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting website',
      promise: MatgenGlobal.Data.deleteMicrosite(id),
    });
    if (response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem deleting the microsite.'
      );
      return false;
    }
    if (row) {
      $(row)
        .parent()
        .parent()
        .DataTable()
        .row($(row))
        .remove()
        .draw();
    }
  }

  static editTemplate(id) {
    if (MatgenGlobal.Router.hash) {
      if (MatgenGlobal.adminView) {
        window.location.href = `${MatgenGlobal.adminView}#/templates/${id}`;
      } else {
        window.location.href = `${MatgenGlobal.RootPage}#/templates/${id}`;
      }
    } else {
      window.location.href = `/templates/${id}`;
    }
  }

  static async buildSectionForm(sections) {
    const s = [];
    for (let i = 0; i < sections.length; i++) {
      const questions = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading questions',
        promise: MatgenUIFunctions.buildSectionQuestions(
          sections[i].id,
          sections[i].type
        ),
      });
      s.push(`
      <div class="tab-pane fade show ${i === 0 ? ' active' : ''}" id="${
        sections[i].type
      }-tab-pane" role="tabpanel" aria-labelledby="${
        sections[i].type
      }-tab" tabindex="0">
        <div class="section-wrapper">
          <div class="section-detail-wrapper">
            <div class="section-detail">
              <div><b>Details:</b></div>
              <div><b>ID:</b> <u id="${sections[i].type}-detail-s-id">${
        sections[i].id
      }</u></div>
              <div><b>Name:</b> <u id="${sections[i].type}-detail-s-name">${
        sections[i].name
      }</u></div>
              <div><b>Title:</b> <u id="${sections[i].type}-detail-s-title">${
        sections[i].title
          ? sections[i].title
          : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
      }</u></div>
              <div><b>Subtext:</b> <u id="${
                sections[i].type
              }-detail-s-subtext">${
        sections[i].subtext
          ? sections[i].subtext
          : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
      }</u></div>
              <div><b>Type:</b> <u id="${sections[i].type}-detail-s-type">${
        sections[i].type
      }</u></div>
            </div>
            <div class="icon-link edit-section" data-id="${
              sections[i].id
            }"><i class="fa-solid fa-pen-to-square"></i></div>
          </div>
          <div id="${sections[i].type}-questions" class="questions">
          ${questions}
          </div>
        </div>
      </div>
    `);
    }
    return s.join('');
  }

  static async buildExampleContent(question_id) {
    let exampleContent = `
    <div class="alert alert-info" role="alert">
      <p><i class="fa-solid fa-circle-info"></i> No examples for this question.<br>Click the plus sign above to add.</p>
    </div>
    `;
    const examples = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading examples',
      promise: MatgenGlobal.Data.getQuestionExamples(question_id),
    });
    examples.sort((a, b) => a.sort_order - b.sort_order);
    if (examples.length > 0) {
      exampleContent = examples
        .map((a, i) => {
          return `
          <div id="${examples[i].id}-example" data-id="${
            examples[i].id
          }" class="example-card">
            <div class="example-title">
              ${a.answer.charAt() == '{' ? JSON.parse(a.answer).html : a.answer}
            </div>
            <div class="example-actions">
              <div style="margin-right:.25em;" class="icon-link edit-example" data-id="${
                a.id
              }"><i class="fa-solid fa-pen-to-square"></i></div>
              <div class="icon-link delete-example" data-id="${
                a.id
              }"><i class="fa-solid fa-trash-can"></i></div>
            </div>
          </div>
        `;
        })
        .join('');
    }
    return { exampleContent, count: examples.length };
  }

  static async buildAnswerContent(question_id, isJSONAnswer = false) {
    let answerContent = `
    <div class="alert alert-info" role="alert">
      <p><i class="fa-solid fa-circle-info"></i> No answers for this question.<br>Click the plus sign above to add.</p>
    </div>
    `;
    const answers = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading answers',
      promise: MatgenGlobal.Data.getQuestionAnswers(question_id),
    });
    answers.sort((a, b) => a.sort_order - b.sort_order);
    if (answers.length > 0) {
      answerContent = answers
        .map((a, i) => {
          return `
          <div id="${answers[i].id}-answer" data-id="${
            answers[i].id
          }" class="answer-card">
            <div class="answer-title">
              <i class="answer-icon ${a.icon}"></i>
              ${a.text}<br>${a.sub_text ? a.sub_text : ''}
            </div>
            ${
              isJSONAnswer
                ? ''
                : `<div style="margin-right:.25em;" class="icon-link edit-answer" data-id="${a.id}"><i class="fa-solid fa-pen-to-square"></i></div>
            <div class="icon-link delete-answer" data-id="${a.id}"><i class="fa-solid fa-trash-can"></i></div>
          </div>`
            }

        `;
        })
        .join('');
    }
    return { answerContent, count: answers.length };
  }

  static async buildExampleAccordion(question_id, accordion = true) {
    const { exampleContent, count } = await MatgenGlobal.MatgenPageLoader.start(
      {
        message: 'Loading example form',
        promise: MatgenUIFunctions.buildExampleContent(question_id),
      }
    );
    return `
    <div>
      <div id="accordion-${question_id}-examples">
      <div class="section-question-examples">
        <div class="answers-controller">
          <div class="answers-controls">
            <div class="answers-controller-title"><b>Examples:</b></div>
            ${
              count > 1
                ? `<button type="button" data-question-id="${question_id}" class="sort-section-question-examples btn btn-primary" style="padding: 0;width:1.8em;margin-right:.25em;"><i class="fa-solid fa-sort"></i></button>`
                : ''
            }
            <button type="button" data-question-id="${question_id}" class="add-section-question-example btn btn-primary" style="padding: 0;width:1.8em;"><i class="fa-solid fa-plus"></i></button>
          </div>
          ${
            accordion
              ? `<div id="accordion-${question_id}-example-content">`
              : ''
          }
            ${exampleContent}
            ${accordion ? `</div>` : ''}
          </div>
        </div>
      </div>
      </div>
    </div>
    `;
  }

  static async buildAnswerAccordion(
    question_id,
    isJSONAnswer = false,
    isRequired = false
  ) {
    const { answerContent, count } = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading answer form',
      promise: MatgenUIFunctions.buildAnswerContent(question_id, isJSONAnswer),
    });
    return `
    <div>
      <div id="accordion-${question_id}-answers">
      <div class="section-question-answers">
        <div class="answers-controller">
          <div class="answers-controls">
            <div class="answers-controller-title"><b>Answer${
              isJSONAnswer ? '' : 's'
            }:</b></div>
            ${
              count > 1
                ? `<button type="button" data-question-id="${question_id}" class="sort-section-question-answers btn btn-primary" style="padding: 0;width:1.8em;margin-right:.25em;"><i class="fa-solid fa-sort"></i></button>`
                : ''
            }
            ${
              isJSONAnswer
                ? `<button type="button" data-question-id="${question_id}" class="add-section-question-answer-json btn btn-primary" style="padding: 0;width:1.8em;"><i class="fa-solid fa-brackets-curly"></i></button>`
                : `<button type="button" data-question-id="${question_id}" class="add-section-question-answer btn btn-primary" style="padding: 0;width:1.8em;"><i class="fa-solid fa-plus"></i></button>`
            }
          </div>
          ${
            isJSONAnswer
              ? `<div>
          <input type="checkbox" class="JSONAddRequired" name="JSONAddRequired" data-question-id="${question_id}" value="${isRequired}">
          <label for="JSONAddRequired"> Is Required</label><br>
          </div>`
              : ``
          }
          <div id="accordion-${question_id}-answers-content">
            ${answerContent}
          </div>
        </div>
      </div>
      </div>
    </div>
    `;
  }

  static buildQuestionAccordion(question, exampleContent, answerContent) {
    return `
    <div class="m4c-accordion-item" data-id="${question.id}">
      <div class="d-flex" id="m4c-accordion-item-${
        question.id
      }" style="border:1px solid #eee;padding:1.5em .75em;">
        <div class="question-title">${
          question.text
        }<br><strong>Subtitle:</strong> ${
      question.sub_text ? question.sub_text : ''
    }<br><strong>Handle:</strong> ${
      question.handle ? question.handle : ''
    }<br><strong>Type:</strong> ${
      question.component ? question.component : ''
    }</div>
        <div class="d-flex">
          <div style="margin-right:.5em;"><i class="icon-link q-edit fa-solid fa-edit" data-id="${
            question.id
          }"></i></div>
          <!--<div style="margin-right:1.5em;"><i class="icon-link q-view fa-solid fa-angles-down" data-id="${
            question.id
          }"></i></div>-->
          <div><i class="icon-link q-delete fa-solid fa-trash-can" data-id="${
            question.id
          }"></i></div>
        </div>
      </div>
      <div id="accordion-content-${
        question.id
      }" class="question-accordion m4c-accordion">
        <div class="m4c-accordion-body">
          ${exampleContent}
          ${answerContent}
        </div>
      </div>
    </div>
    `;
  }

  static isJsonAnswer(component) {
    switch (component) {
      default:
        throw new Error(`Invalid component type: ${component}`);
      case 'date-range':
      case 'img-select-single':
      case 'list-faq':
      case 'list-multi-input':
      case 'multi-input':
      case 'range':
      case 'text':
      case 'richtext':
      case 'textarea':
      case 'upload-single':
        return true;
      case 'select-multiple':
      case 'select-single':
      case 'card-select-single':
      case 'box-select-single':
      case 'list-select-single':
        return false;
    }
  }

  static async buildSectionQuestions(id, type) {
    if (type === 'materials') {
      return `
      <div class="alert alert-info" role="alert" style="margin-top:1em;">
        <p><i class="fa-solid fa-circle-info"></i> The materials section exists only to provide the ability to edit the section information for it. The "question" is the section name/title/subtext, and the "answers" are the listed materials.</p>
      </div>
      `;
    }
    const questions = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading questions',
      promise: MatgenGlobal.Data.getSectionQuestions(id),
    });
    questions.sort((a, b) => a.sort_order - b.sort_order);
    let q;
    if (questions.length > 0) {
      q = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading questionnaire form',
        promise: Promise.all(
          questions.map(async (q, i) => {
            const exampleAccordion = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading example form',
              promise: MatgenUIFunctions.buildExampleAccordion(questions[i].id),
            });
            const answerAccordion = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading answer form',
              promise: MatgenUIFunctions.buildAnswerAccordion(
                questions[i].id,
                MatgenUIFunctions.isJsonAnswer(questions[i].component)
              ),
            });
            return MatgenUIFunctions.buildQuestionAccordion(
              questions[i],
              exampleAccordion,
              answerAccordion
            );
          })
        ),
      });
      q = q.join('');
    } else {
      q = `
      <div class="alert alert-info" role="alert">
        <p><i class="fa-solid fa-circle-info"></i> No questions in this section. Click the plus sign above to add.</p>
      </div>
      `;
    }

    return `
    <div class="section-questions">
      <div class="questions-controller">
        <div class="questions-controls">
          <div class="questions-controller-title"><b>Questions:</b></div>
          <button type="button" data-section-id="${id}" class="sort-section-questions btn btn-primary" style="padding: 0;width:1.8em;margin-right:.25em;"><i class="fa-solid fa-sort"></i></button>
          <button type="button" data-section-id="${id}" class="add-section-question btn btn-primary" style="padding: 0;width:1.8em;"><i class="fa-solid fa-plus"></i></button>
        </div>
        <div id="accordion-${type}">
        ${q}
        </div>
      </div>
    </div>
    `;
  }

  static async deleteQuestionExample(id) {
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting answer',
      promise: MatgenGlobal.Data.deleteExample(id),
    });
    const p = $(`#${id}-answer`).parent();
    $(`#${id}-example`).remove();
    if (p.find('.example-card').length === 0) {
      p.append(`
        <div class="alert alert-info" role="alert">
          <p><i class="fa-solid fa-circle-info"></i> No example for this question.<br>Click the plus sign above to add.</p>
        </div>
      `);
    }
  }

  static async deleteQuestionAnswer(id) {
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting answer',
      promise: MatgenGlobal.Data.deleteAnswer(id),
    });
    const p = $(`#${id}-answer`).parent();
    $(`#${id}-answer`).remove();
    if (p.find('.answer-card').length === 0) {
      p.append(`
        <div class="alert alert-info" role="alert">
          <p><i class="fa-solid fa-circle-info"></i> No answers for this question.<br>Click the plus sign above to add.</p>
        </div>
      `);
    }
  }

  static async deleteSectionQuestion(id) {
    const answers = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading answers',
      promise: MatgenGlobal.Data.getQuestionAnswers(id),
    });
    const answerDeletes = [];
    for (let i = 0; i < answers.length; i++) {
      answerDeletes.push(MatgenGlobal.Data.deleteAnswer(answers[i].id));
    }
    if (answerDeletes.length > 0) {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Deleting answers',
        promise: Promise.all(answerDeletes),
      });
    }

    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting question',
      promise: MatgenGlobal.Data.deleteQuestion(id),
    });
    const container = $(`[data-id=${id}]`).parent();

    $(`[data-id=${id}]`).remove();
    if (container.find('.m4c-accordion-item').length === 0) {
      container.append(
        $(`
      <div class="alert alert-info" role="alert">
        <p><i class="fa-solid fa-circle-info"></i> No questions in this section. Click the plus sign above to add.</p>
      </div>
      `)
      );
    }
  }

  static async editAnswerData(id) {
    try {
      const answer = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading answer',
        promise: MatgenGlobal.Data.getAnswer(id),
      });

      const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Creating form',
        promise: new MatgenFormsComponents.AnswerForm({
          answer: answer,
        }),
      });

      const content = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading form',
        promise: DynamicForm.form.getElement(),
      });

      MatgenGlobal.M4CModal.show({
        id: 'edit-section-modal',
        title: `Edit Section`,
        content: content[0].outerHTML,
        buttons: [
          {
            id: `edit-section-submit`,
            classname: 'primary btn btn-primary',
            label: `Save`,
          },
        ],
        width: '550px',
      });
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'Failed to retrieve answer data from the API.'
      );
      return false;
    }
  }

  static async editSectionData(id) {
    try {
      const section = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading section',
        promise: MatgenGlobal.Data.getSection(id),
      });
      const inputs = [
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Name',
            id: 'inputName',
            dataId: 'name',
            value: section.name,
          },
        },
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Title',
            id: 'inputTitle',
            dataId: 'title',
            value: section.title ? section.title : '',
          },
        },
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Subtext',
            id: 'inputSubtext',
            dataId: 'sub_text',
            value: section.sub_text ? section.sub_text : '',
          },
        },
        {
          component: 'RawHTML',
          html: `
          <input type="hidden" id="section-id" name="section-id" value="${section.id}"/>
          `,
        },
      ];
      const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading form',
        promise: new MatgenForms.DynamicForm(inputs, [], 'edit-section-form'),
      });
      const content = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading form content',
        promise: DynamicForm.form.getElement(),
      });
      MatgenGlobal.M4CModal.show({
        id: 'edit-section-modal',
        title: `Edit Section`,
        content: content[0].outerHTML,
        buttons: [
          {
            id: `edit-section-submit`,
            classname: 'primary btn btn-primary',
            label: `Save`,
          },
        ],
        width: '550px',
      });
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'Failed to retrieve answer data from the API.'
      );
      return false;
    }
  }

  static async editQuestionnaireData(id) {
    try {
      const questionnaire = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading questionnaire',
        promise: MatgenGlobal.Data.getQuestionnaireRecord(id),
      });
      const inputs = [
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Name',
            id: 'inputName',
            required: true,
            value: questionnaire.name,
          },
        },
        {
          component: 'Select',
          options: {
            label: 'Tenant',
            id: 'inputTenantID',
            classes: 'middle',
            required: true,
            options: async () => {
              const tenants = await MatgenGlobal.MatgenPageLoader.start({
                message: 'Loading tenants',
                promise: MatgenGlobal.Data.getTenants(),
              });
              return tenants.map(t => {
                return {
                  label: t.name,
                  value: t.id,
                };
              });
            },
            value: questionnaire.tenant_id,
          },
        },
        {
          component: 'Checkbox',
          options: {
            type: 'checkbox',
            label: 'Active',
            id: 'inputActive',
            dataId: 'active',
            value: questionnaire.active,
          },
        },
        {
          component: 'RawHTML',
          html: `
          <input type="hidden" id="questionnaire-id" name="questionnaire-id" value="${questionnaire.id}"/>
          `,
        },
      ];
      const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading form',
        promise: new MatgenForms.DynamicForm(
          inputs,
          [],
          'edit-questionnaire-form'
        ),
      });
      const content = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading form content',
        promise: DynamicForm.form.getElement(),
      });
      MatgenGlobal.M4CModal.show({
        id: 'edit-questonnaire-modal',
        title: `Edit Questionnaire`,
        content: content[0].outerHTML,
        buttons: [
          {
            id: `edit-questionnaire-submit`,
            classname: 'primary btn btn-primary',
            label: `Save`,
          },
        ],
        width: '550px',
      });
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'Failed to retrieve questionnaire data from the API.'
      );
      return false;
    }
  }

  static async createExample(question_id) {
    MatgenGlobal.ChosenIcon = {
      prefix: 'solid',
      name: 'circle-question',
    };

    const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: new MatgenFormsComponents.ExampleForm({
        question_id: question_id,
      }),
    });
    const content = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: DynamicForm.form.getElement(),
    });

    MatgenGlobal.M4CModal.show({
      id: 'create-example-modal',
      title: `Create Example`,
      content: content[0].outerHTML,
      buttons: [
        {
          id: `create-example-submit`,
          classname: 'primary btn btn-primary',
          label: `Create`,
        },
      ],
      width: '550px',
    });

    new M4CRichTextEditor({
      id: 'instructionsRTEExamples',
      targetSelector: '#inputRichText',
      changeHandler: e => {
        console.log(JSON.stringify(e));
      },
    });

    $('#inputRichText').hide();
    $('.ql-toolbar').hide();
  }

  static async createAnswer(question_id) {
    MatgenGlobal.ChosenIcon = {
      prefix: 'solid',
      name: 'circle-question',
    };

    const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: new MatgenFormsComponents.AnswerForm({
        question_id: question_id,
      }),
    });
    const content = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: DynamicForm.form.getElement(),
    });

    MatgenGlobal.M4CModal.show({
      id: 'create-answer-modal',
      title: `Create Answer`,
      content: content[0].outerHTML,
      buttons: [
        {
          id: `create-answer-submit`,
          classname: 'primary btn btn-primary',
          label: `Create`,
        },
      ],
      width: '550px',
    });
  }

  static async createAnswerJson(question_id) {
    MatgenGlobal.ChosenIcon = {
      prefix: 'solid',
      name: 'circle-question',
    };

    const answers = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading answers',
      promise: MatgenGlobal.Data.getQuestionAnswers(question_id),
    });

    const answer = answers.length === 1 ? answers[0] : null;

    const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: new MatgenFormsComponents.AnswerJSONForm({
        question_id: question_id,
        answer,
      }),
    });
    const content = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: DynamicForm.form.getElement(),
    });

    if (answers.length) {
      MatgenGlobal.M4CModal.show({
        id: 'edit-answer-modal',
        title: `Edit Answer`,
        content: content[0].outerHTML,
        buttons: [
          {
            id: `edit-answer-submit-json`,
            classname: 'primary btn btn-primary',
            label: `Edit`,
          },
        ],
        width: '550px',
      });
    } else {
      MatgenGlobal.M4CModal.show({
        id: 'create-answer-modal',
        title: `Create Answer`,
        content: content[0].outerHTML,
        buttons: [
          {
            id: `create-answer-submit-json`,
            classname: 'primary btn btn-primary',
            label: `Create`,
          },
        ],
        width: '550px',
      });
    }
  }

  static async createQuestion(section_id) {
    const inputs = [
      {
        component: 'Select',
        options: {
          label: 'Type',
          id: 'inputComponent',
          classes: 'middle',
          required: true,
          options: [
            {
              label: 'Text',
              value: 'text',
            },
            {
              label: 'Single select',
              value: 'select-single',
            },
            {
              label: 'Textarea',
              value: 'textarea',
            },
            {
              label: 'Rich Text',
              value: 'richtext',
            },
            {
              label: 'Range',
              value: 'range',
            },
            {
              label: 'Multi select',
              value: 'select-multiple',
            },
            {
              label: 'Single List Select',
              value: 'list-select-single',
            },
            {
              label: 'Single Image Select',
              value: 'img-select-single',
            },
            {
              label: 'FAQ List',
              value: 'list-faq',
            },
            {
              label: 'Single Upload',
              value: 'upload-single',
            },
            {
              label: 'Multi List Input',
              value: 'list-multi-input',
            },
          ],
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Text',
          id: 'inputText',
          required: true,
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Subtext',
          id: 'inputSubtext',
        },
      },
      {
        component: 'Checkbox',
        options: {
          type: 'checkbox',
          label: 'Insert Subtext',
          id: 'inputInsertSubText',
          value: true,
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Handle',
          id: 'inputHandle',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'number',
          label: 'Sort Order',
          id: 'inputSortOrder',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'List Add Name',
          id: 'inputListAddName',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Tooltip',
          id: 'inputTooltip',
        },
      },
      {
        component: 'RawHTML',
        html: `
        <label for="inputInstructions" class="col-5 col-form-label">Instructions</label><br><div id="inputInstructions"></div>
          `,
      },
      {
        component: 'RawHTML',
        html: `
          <input type="hidden" id="q-section-id" name="q-section-id" value="${section_id}"/>
          `,
      },
    ];

    const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: new MatgenForms.DynamicForm(inputs, [], 'create-question-form'),
    });

    const content = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: DynamicForm.form.getElement(),
    });
    MatgenGlobal.M4CModal.show({
      id: 'create-question-modal',
      title: `Create Question`,
      content: content[0].outerHTML,
      buttons: [
        {
          id: `create-question-submit`,
          classname: 'primary btn btn-primary',
          label: `Create`,
        },
      ],
      width: '550px',
    });

    new M4CRichTextEditor({
      id: 'instructionsRTE',
      targetSelector: '#inputInstructions',
      changeHandler: e => {
        console.log(JSON.stringify(e));
      },
    });
  }

  static async editQuestionnaire(id) {
    try {
      let questionnaire = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading questionnaire data',
        promise: MatgenGlobal.Data.getQuestionnaireRecord(id),
      });

      if (Array.isArray(questionnaire) && questionnaire.length > 0) {
        questionnaire = questionnaire[0];
      }

      const sections = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading questionnaire sections',
        promise: MatgenGlobal.Data.getQuestionnaireSections(id),
      });

      sections.sort((a, b) => {
        return a.sort_order - b.sort_order;
      });

      const sectionQuestions = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading section questions',
        promise: MatgenUIFunctions.buildSectionForm(sections),
      });

      const tenant = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading tenant data',
        promise: MatgenGlobal.Data.getTenant(questionnaire.tenant_id),
      });

      MatgenGlobal.UI.showPage(`
        <div style="width:75%;margin-left:auto;margin-right:auto;">
          <h2>Edit Questionnaire</h2>
          <div class="questionnaire-detail">
            <div class="questionnaire-details-wrapper">
              <div><b>Details:</b></div>
              <div>ID: <u id="detail-q-id">${questionnaire.id}</u></div>
              <div>Name: <u id="detail-q-name">${questionnaire.name}</u></div>
              <div>Type: <u id="detail-q-type">${questionnaire.type}</u></div>
              <div>Tenant: <u id="detail-q-tenant">${tenant.name}</u></div>
              <div>Active: <u id="detail-q-active">${
                questionnaire.active ? 'Y' : 'N'
              }</u></div>
            </div>
            <div class="icon-link edit-questionnaire" data-id="${
              questionnaire.id
            }"><i class="fa-solid fa-pen-to-square"></i></div>
          </div>
          <div class="questionnaire-sections">
            ${sections.length > 1 ? '<div><b>Sections:</b></div>' : ''}

            <ul class="nav nav-tabs" id="section-tabs-nav" role="tablist">

            ${
              sections.length > 1
                ? sections
                    .map((s, i) => {
                      return `
                <li class="nav-item" role="presentation">
                  <button class="nav-link${i === 0 ? ' active' : ''}" id="${
                        s.type
                      }-tab" data-bs-toggle="tab" data-bs-target="#${
                        s.type
                      }-tab-pane" type="button" role="tab" aria-controls="${
                        s.type
                      }-tab-pane" aria-selected="true">${s.name}</button>
                </li>
              `;
                    })
                    .join('')
                : ''
            }
            </ul>
            <div class="tab-content" id="section-tabs">

            ${sectionQuestions}

            </div>
          </div>
        </div>
      `);
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'Failed to retrieve questionnaire data from the API.'
      );
      return false;
    }
  }

  static async createQuestionnaire() {
    const sectionInputs = [
      'goal',
      'demographics',
      'distribution',
      'materials',
    ].map(s => {
      const nameInput = new TextInput({
        type: 'text',
        label: 'Name',
        id: `section-${s}-name`,
        value: s.charAt(0).toUpperCase() + s.slice(1),
        required: true,
      });
      const titleInput = new TextInput({
        type: 'text',
        label: 'Title',
        id: `section-${s}-title`,
      });
      const subtextInput = new TextInput({
        type: 'text',
        label: 'Subtext',
        id: `section-${s}-subtext`,
      });

      return [nameInput, titleInput, subtextInput];
    });

    const formInputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Name',
          id: 'inputName',
          required: true,
        },
      },
      {
        component: 'Select',
        options: {
          label: 'Tenant',
          id: 'inputTenantID',
          classes: 'middle',
          required: true,
          options: async () => {
            const tenants = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading tenants',
              promise: MatgenGlobal.Data.getTenants(),
            });
            return tenants.map(t => {
              return {
                label: t.name,
                value: t.id,
              };
            });
          },
        },
      },
      {
        component: 'Select',
        options: {
          type: 'text',
          label: 'Type',
          id: 'questionnaireType',
          classes: 'middle',
          required: true,
          value: 'material',
          helpText:
            "If you don't know what to put in here, you shouldn't touch this.",
          options: [
            {
              label: 'Material',
              value: 'material',
            },
            {
              label: 'Microsite',
              value: 'microsite',
            },
            {
              label: 'Template',
              value: 'template',
            },
          ],
        },
      },
    ];

    for (let i = 0; i < sectionInputs.length; i++) {
      const inputs = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading form inputs',
        promise: Promise.all(
          sectionInputs[i].map(i2 => (i2.getHTML ? i2.getHTML() : i2))
        ),
      });
      let s = 'Goal';
      switch (i) {
        default:
        case 0:
          break;
        case 1:
          s = 'Demographics';
          break;
        case 2:
          s = 'Distribution';
          break;
        case 3:
          s = 'Materials';
          break;
      }
      formInputs.push({
        component: 'RawHTML',
        html: `
        <div class="questionnaire-section" style="border:1px solid #ccc;padding:1.5em;margin-top:2em;border-radius:4px;padding-bottom: 0.25em;">
          <div class="section-label" style="padding-bottom:.5em;"><u>${s} Section:</u></div>
          <div class="section-inputs" style="">
            <div class="section-name">${inputs[0]}</div>
            <div class=section-title-wrapper style="display:none;" id="${s}-title">
              <div class="section-title">${inputs[1]}</div>
            </div>
            <div class=section-subtext-wrapper style="display:none;" id="${s}-subtext">
              <div class="section-subtext">${inputs[2]}</div>
            </div>
            <div class="section-options" style="display: flex;justify-content: space-evenly;">
              <a href="#" class="section-toggle" data-id="${s}-title">Add title/question</a>
              <a href="#" class="section-toggle" data-id="${s}-subtext">Add subtext</a>
            </div>
          </div>
        </div>
        `,
      });
    }

    const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: new MatgenForms.DynamicForm(
        formInputs,
        [],
        'create-questionnaire-form'
      ),
    });
    const content = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: DynamicForm.form.getElement(),
    });
    MatgenGlobal.M4CModal.show({
      id: 'create-questonnaire-modal',
      title: `Create New Questionnaire`,
      content: content[0].outerHTML,
      buttons: [
        {
          id: `create-questionnaire-submit`,
          classname: 'primary btn btn-primary',
          label: `Create`,
        },
      ],
      width: '650px',
    });
  }

  static async addAnswer(qid, id = null) {
    const modalId = `answer-form-modal`;
    let args;
    let action = 'create';
    let action_title = 'Add';

    if (id) {
      action = 'edit';
      action_title = 'Edit';
    }

    const Form = MatgenForms.SectionAnswerForm;
    const formHTML = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: Form.getHTML(),
    });
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: `${action_title} Answer`,
      content: formHTML,
      buttons: [
        {
          id: `${action}-answer-submit`,
          classname: 'primary btn btn-primary',
          label: `${action_title}`,
        },
      ],
      width: '550px',
    });

    $(`#answer-form h1`).hide();
    $(`#answer-form button:not(#toggle-icon-chooser)`).hide();

    $(`#${modalId}`).modal('toggle');

    $(document).off('click', `#${action}-answer-submit`);
    $(document).on('click', `#${action}-answer-submit`, () => {
      $(`#answer-form`).submit();
    });

    $(document).off('submit', `#answer-form`);
    $(document).on('submit', `#answer-form`, async e => {
      e.preventDefault();

      args = {
        question_id: $('#inputQuestionID').val(),
        text: $('#inputText').val(),
        sub_text: $('#inputSubtext').val(),
        sort_order: $('#inputSortOrder').val(),
        icon: $('#inputIconButton').val(),
        id: id ? id : UUID(),
      };

      MatgenGlobal.UI.validateForm(`answer-form`, async () => {
        $(`.list-group[data-id="${qid}"] .answer-container`).append(`
          <li class="list-group-item answer" style="display: flex; justify-content: space-between;" data-question-id="${qid}" data-id="${args.id}" data-sub-text="${args.sub_text}" data-sort-order="${args.sort_order}">
            <div style="width: 75%;" class="answer-text">${args.text}</div>
          </li>
        `);
        $(`#answer-form-modal`).modal('hide');
      });
    });
  }

  static async editQuestion() {
    const form = MatgenGlobal.DynamicForms['edit-question-form'];
    const valid = form.validate();

    if (valid === true) {
      const sid = $('#q-section-id').val();
      const id = $('#q-id').val();
      const questions = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading section questions',
        promise: MatgenGlobal.Data.getSectionQuestions(sid),
      });
      const sort_orders = questions.map(q => q.sort_order);
      sort_orders.sort();
      let max_sort_order = sort_orders[sort_orders.length - 1];
      const values = form.getValues();
      if (!max_sort_order) {
        max_sort_order = 0;
      }
      const question = {
        id,
        text: values.find(v => v.id === 'inputText').value,
        sub_text: values.find(v => v.id === 'inputSubtext').value,
        component: values.find(v => v.id === 'inputComponent').value,
        section_id: sid,
        sort_order: max_sort_order + 1,
      };
      $('#edit-question-modal').modal('hide');
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving question',
          promise: MatgenGlobal.Data.saveItem('questions', question, true),
        });
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the question.'
        );
        return false;
      }

      window.location.reload();
    }
  }

  static async saveForm(type, name, id = null) {
    const modalId = `${type}-form-modal`;

    let Form;
    let args;
    let action = 'create';
    let action_title = 'Create';
    let questionOptions = [
      { label: 'Select Single', value: 'select-single' },
      { label: 'Select Multiple', value: 'select-multiple' },
    ];
    let section_id;

    if (type === 'question') {
      const question = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading question',
        promise: MatgenGlobal.Data.getQuestion(id),
      });
      section_id = question.section_id;
      const section = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading section',
        promise: MatgenGlobal.Data.getSection(question.section_id),
      });

      const questionnaire = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading questionnaire',
        promise: MatgenGlobal.Data.getQuestionnaireRecord(
          section.questionnaire_id
        ),
      });

      if (questionnaire.type === 'microsite') {
        questionOptions = [
          { label: 'Select Single', value: 'select-single' },
          { label: 'Select Multiple', value: 'select-multiple' },
          { label: 'Text', value: 'text' },
          { label: 'Text Area', value: 'textarea' },
          { label: 'Range', value: 'range' },
          { label: 'Card Select Single', value: 'card-select-single' },
          { label: 'List Select Single', value: 'list-select-single' },
          { label: 'Image Select Single', value: 'img-select-single' },
          { label: 'List FAQ', value: 'list-faq' },
          { label: 'Upload Single', value: 'upload-single' },
          { label: 'Multi-Input', value: 'multi-input' },
          { label: 'List Multi-Input', value: 'list-multi-input' },
        ];
      }
    }

    const questionInputs = [
      {
        component: 'Select',
        options: {
          label: 'Select Component',
          id: 'inputComponent',
          classes: 'top',
          options: questionOptions,
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Text',
          id: 'inputText',
          classes: 'middle',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'subtext',
          label: 'Subtext',
          id: 'inputSubtext',
          classes: 'middle',
        },
      },
      {
        component: 'RawHTML',
        html: `
          <input type="hidden" id="q-id" name="q-id" value="${id}"/>
          `,
      },
      {
        component: 'Checkbox',
        options: {
          type: 'checkbox',
          label: 'Insert Subtext',
          id: 'inputInsertSubText',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Handle',
          id: 'inputHandle',
          classes: 'top',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'number',
          label: 'Sort Order',
          id: 'inputSortOrder',
          classes: 'middle',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'List Add Name',
          id: 'inputListAddName',
          classes: 'middle',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Tooltip',
          id: 'inputTooltip',
          classes: 'middle',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Instructions',
          id: 'inputInstructions',
          classes: 'bottom',
        },
      },

      {
        component: 'RawHTML',
        html: `
          <input type="hidden" id="q-section-id" name="q-section-id" value="${section_id}"/>
          `,
      },
    ];

    if (id) {
      action = 'edit';
      action_title = 'Edit';
    }
    switch (type) {
      default:
        break;
      case 'tenant':
        Form = MatgenForms.TenantForm;
        break;
      case 'questionnaire':
        Form = MatgenForms.QuestionnaireForm;
        break;
      case 'section':
        Form = MatgenForms.SectionForm;
        break;
      case 'question':
        Form = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading form',
          promise: new MatgenForms.DynamicForm(
            questionInputs,
            [],
            'edit-question-form'
          ),
        });
        Form = Form.form;
        break;
      case 'answer':
        Form = MatgenFormsComponents.AnswerForm;
        break;
      case 'example':
        Form = MatgenFormsComponents.ExampleForm;
        break;
    }
    const formHTML = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: Form.getHTML(),
    });
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: `${action_title} ${name}`,
      content: formHTML,
      buttons: [
        {
          id: `${action}-${type}-submit`,
          classname: 'primary btn btn-primary',
          label: `${action_title}`,
        },
      ],
      width: '550px',
    });

    $(`#${type}-form h1`).hide();
    $(`#${type}-form button:not(#toggle-icon-chooser)`).hide();
    switch (type) {
      default:
        break;
      case 'tenant':
        if (id) {
          const item = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading tenant',
            promise: MatgenGlobal.Data.getTenant(id),
          });
          $('#inputName').val(item.name);
          $('#inputIs508').val(item.is508);
        }
        break;
      case 'questionnaire':
        if (id) {
          const item = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading questionnaire',
            promise: MatgenGlobal.Data.getQuestionnaire(id),
          });
          $('#inputName').val(item.name);
          $('#inputTenantID').val(item.tenant_id);
          $('#inputType').val(item.type);
          $('#inputActive').val(item.active);
        }
        break;
      case 'section':
        if (id) {
          const item = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading section',
            promise: MatgenGlobal.Data.getSection(id),
          });
          $('#inputName').val(item.name);
          $('#inputQuestionnaireID').val(item.questionnaire_id);
          $('#inputType').val(item.type);
          $('#inputTitle').val(item.title);
          $('#inputSubtext').val(item.subtext);
          $('#inputSortOrder').val(item.sort_order);
        }
        break;
      case 'question':
        if (id) {
          const item = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading question',
            promise: MatgenGlobal.Data.getQuestion(id),
          });
          $('#inputComponent').val(item.component);
          $('#inputText').val(item.text);
          $('#inputSubtext').val(item.sub_text);
          $('#inputSortOrder').val(item.sort_order);
          $('#inputListAddName').val(item.list_add_name);
          $('#inputTooltip').val(item.tooltip);
          $('#inputInsertSubText').val(item.insert_sub_text);
          $('#section-id').val(item.section_id);
        }
        break;
      case 'answer':
        if (id) {
          const item = await MatgenGlobal.MatgenPageLoader.start({
            message: 'Loading answer',
            promise: MatgenGlobal.Data.getAnswer(id),
          });
          $('#inputQuestionID').val(item.question_id);
          $('#inputText').val(item.text);
          $('#inputSubtext').val(item.sub_text);
          $('#inputSortOrder').val(item.sort_order);
          $('#question-id').val(item.question_id);
          $('#answer-icon')
            .empty()
            .append(`<i class="${item.icon}"></i>`);
        }
        break;
    }

    $(`#${modalId}`).modal('toggle');

    $(document).off('click', `#${action}-${type}-submit`);
    $(document).on('click', `#${action}-${type}-submit`, e => {
      e.preventDefault();
      if (action === 'edit' && type === 'question') {
        MatgenUIFunctions.editQuestion();
      } else {
        $(`#${type}-form`).submit();
      }
    });

    $(document).off('submit', `#${type}-form`);
    $(document).on('submit', `#${type}-form`, async e => {
      e.preventDefault();

      switch (type) {
        default:
          break;
        case 'tenant':
          args = {
            name: $('#inputName').val(),
            is508: $('#inputIs508').val() == '1' ? 1 : 0,
            id: id ? id : UUID(),
          };
          break;
        case 'questionnaire':
          args = {
            name: $('#inputName').val(),
            tenant_id: $('#inputTenantID').val(),
            type: $('#inputType').val(),
            active: $('#inputActive').val(),
            id: id ? id : UUID(),
          };
          break;
        case 'section':
          args = {
            name: $('#inputName').val(),
            questionnaire_ID: $('#inputQuestionnaireID').val(),
            type: $('#inputType').val(),
            title: $('#inputTitle').val(),
            subtext: $('#inputSubtext').val(),
            sort_order: $('#inputSortOrder').val(),
            id: id ? id : UUID(),
          };
          break;
        case 'question':
          args = {
            section_id: $('#inputSectionID').val(),
            component: $('#inputComponent').val(),
            text: $('#inputText').val(),
            sub_text: $('#inputSubtext').val(),
            sort_order: $('#item.sort_order').val(),
            list_add_name: $('#inputListAddName').val(),
            tooltip: $('#inputTooltip').val(),
            insert_sub_text: $('#inputInsertSubText').val(),
            id: id ? id : UUID(),
          };
          break;
        case 'answer':
          args = {
            question_id: $('#question-id').val(),
            text: $('#inputText').val(),
            sub_text: $('#inputSubtext').val(),
            icon: `${$('#answer-icon svg').attr('data-prefix')} fa-${$(
              '#answer-icon svg'
            ).attr('data-icon')}`,
            id: id ? id : UUID(),
          };
          break;
      }

      MatgenGlobal.UI.validateForm(`${type}-form`, async () => {
        await MatgenGlobal.MatgenPageLoader.start({
          message: `Saving ${type}`,
          promise: MatgenGlobal.Data.saveItem(type, args, id ? true : false),
        });
        $(`#${type}-form-modal`).modal('hide');
        if (['question', 'answer'].includes(type)) {
          if (type === 'question') {
            $(`[data-id=${args.id}]`)
              .find('.question-title')
              .text(args.text);
          }
          if (type === 'answer') {
            $(`[data-id=${args.id}]`)
              .find('.answer-title')
              .text(args.text);
            $(`[data-id=${args.id}]`)
              .find('.answer-title svg')
              .remove();
            $(`[data-id=${args.id}]`)
              .find('.answer-title')
              .prepend(`<i class="${args.icon}"></i>`);
          }
        } else {
          MatgenGlobal.Router.core.reload();
        }
      });
    });
  }

  static viewQuestionnaires(id) {
    if (MatgenGlobal.Router.hash) {
      window.location.href = `/${MatgenGlobal.RootPage}#/questionnaires?tenant_id=${id}`;
    } else {
      window.location.href = `/questionnaires?tenant_id=${id}`;
    }
  }

  static viewSections(id) {
    if (MatgenGlobal.Router.hash) {
      window.location.href = `/${MatgenGlobal.RootPage}#/sections?questionnaire_id=${id}`;
    } else {
      window.location.href = `/sections?questionnaire_id=${id}`;
    }
  }

  static viewQuestions(id) {
    if (MatgenGlobal.Router.hash) {
      window.location.href = `/${MatgenGlobal.RootPage}#/questions?section_id=${id}`;
    } else {
      window.location.href = `/questions?section_id=${id}`;
    }
  }

  static viewAnswers(id) {
    if (MatgenGlobal.Router.hash) {
      window.location.href = `/${MatgenGlobal.RootPage}#/answers?question_id=${id}`;
    } else {
      window.location.href = `/answers?question_id=${id}`;
    }
  }

  static async sortExamples(question_id) {
    const examples = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading examples',
      promise: MatgenGlobal.Data.getQuestionExamples(question_id),
    });
    examples.sort((a, b) => a.sort_order - b.sort_order);
    const exampleContent = `
    <div id="sort-div">
      <ul id="sort-list">
        ${examples
          .map(
            a => `
          <li data-question-id="${question_id}" data-id="${a.id}">${
              a.answer.charAt() == '{' ? JSON.parse(a.answer).html : a.answer
            }</li>
        `
          )
          .join('')}
      </ul>
    </div>
    `;
    MatgenGlobal.M4CModal.show({
      id: 'sort-example-modal',
      title: 'Sort Examples',
      content: exampleContent,
      buttons: [
        {
          id: 'sort-example-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
    });
    $('#sort-list').sortable();
  }

  static async sortAnswers(question_id) {
    const answers = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading answers',
      promise: MatgenGlobal.Data.getQuestionAnswers(question_id),
    });
    answers.sort((a, b) => a.sort_order - b.sort_order);
    const answerContent = `
    <div id="sort-div">
      <ul id="sort-list">
        ${answers
          .map(
            a => `
          <li data-question-id="${question_id}" data-id="${a.id}">${a.text}</li>
        `
          )
          .join('')}
      </ul>
    </div>
    `;
    MatgenGlobal.M4CModal.show({
      id: 'sort-answer-modal',
      title: 'Sort Answers',
      content: answerContent,
      buttons: [
        {
          id: 'sort-answer-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
    });
    $('#sort-list').sortable();
  }

  static async sortQuestions(section_id) {
    const questions = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading questions',
      promise: MatgenGlobal.Data.getSectionQuestions(section_id),
    });
    questions.sort((a, b) => a.sort_order - b.sort_order);
    const questionContent = `
    <div id="sort-div">
      <ul id="sort-list">
        ${questions
          .map(
            q => `
          <li data-section-id="${section_id}" data-id="${q.id}">${q.text}</li>
        `
          )
          .join('')}
      </ul>
    </div>
    `;
    MatgenGlobal.M4CModal.show({
      id: 'sort-question-modal',
      title: 'Sort Questions',
      content: questionContent,
      buttons: [
        {
          id: 'sort-question-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
    });
    $('#sort-list').sortable();
  }

  static async tenantFormEdit(id) {
    const tenant = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading tenant',
      promise: MatgenGlobal.Data.getTenant(id),
    });
    const modalId = 'tenant-form-modal';

    const formHTML = MatgenForms.TenantForm.getHTML();

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Create/Edit Tenant',
      content: formHTML,
      buttons: [
        {
          id: 'create-tenant-submit',
          classname: 'primary btn btn-primary',
          label: 'Create',
        },
      ],
    });

    $('#tenant-form h1').hide();
    $('#tenant-form button').hide();
    $('#inputName').val(tenant.name);
    $('#InputIs508').val(tenant.is508);

    $(document).off('click', '#edit-tenant-submit');
    $(document).on('click', '#edit-tenant-submit', () => {
      $('#tenant-form').submit();
    });
    $(document).off('submit', '#tenant-form');
    $(document).on('submit', '#tenant-form', e => {
      e.preventDefault();
      MatgenGlobal.UI.validateForm('tenant-form', async () => {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving tenant',
          promise: MatgenGlobal.Data.saveTenant(
            {
              name: $('#inputName').val(),
              is508: $('#InputIs508').val() == '1' ? 1 : 0,
              id: id,
            },
            true
          ),
        });
        $('#tenant-form-modal').modal('hide');
        MatgenGlobal.Router.core.reload();
      });
    });
  }

  static async questionnaireFormEdit(id) {
    const questionnaire = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading questionnaire',
      promise: MatgenGlobal.Data.getQuestionnaire(id),
    });
    const modalId = 'questionnaire-form-modal';
    const formHTML = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: MatgenForms.QuestionnaireForm.getHTML(),
    });
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Edit Questionnaire',
      content: formHTML,
      buttons: [
        {
          id: 'edit-questionnaire-submit',
          classname: 'primary btn btn-primary',
          label: 'Edit',
        },
      ],
    });

    $('#questionnaire-form h1').hide();
    $('#questionnaire-form button').hide();
    $('#inputName').val(questionnaire.name);
    $('#InputIs508').val(questionnaire.is508);

    $(document).off('click', '#edit-questionnaire-submit');
    $(document).on('click', '#edit-questionnaire-submit', () => {
      $('#questionnaire-form').submit();
    });
    $(document).off('submit', '#questionnaire-form');
    $(document).on('submit', '#questionnaire-form', e => {
      e.preventDefault();
      MatgenGlobal.UI.validateForm('questionnaire-form', async () => {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading questionnaire',
          promise: MatgenGlobal.Data.saveQuestionnaire(
            {
              name: $('#inputName').val(),
              is508: $('#InputIs508').val() == '1' ? 1 : 0,
              id: id,
            },
            true
          ),
        });
        $('#questionnaire-form-modal').modal('hide');
        MatgenGlobal.Router.core.reload();
      });
    });
  }

  static async addOption(id) {
    const obj = MatgenGlobal.UI.findById(
      MatgenGlobal.editor.cur().fabric.getObjects(),
      id
    );
    MatgenGlobal.UI.promptModal({
      title: 'Alt Text For Image:',
      label: 'Alt Text:',
      action: alt => {
        const uploader = new Uploader();
        obj.altText = alt;
        uploader.fileSelect(`uploader-${id}`, async () => {
          const file = document.getElementById(`uploader-${id}`).files[0];
          if (file) {
            const durl = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Uploading file',
              promise: uploader.bufferUpload(file),
            });
            MatgenGlobal.UI.replaceCanvasImage(obj, durl);
            MatgenGlobal.sidebar.markComponentDirty(obj.componentId);
          }
        });
      },
      buttonText: 'Continue',
    });
  }

  static async addOptionOld(id) {
    const obj = MatgenGlobal.UI.findById(
      MatgenGlobal.editor.cur().fabric.getObjects(),
      id
    );

    const uploaderForm = `
      <form id="image-upload-form">
        <div class="form-group">
          <div>
            Choose an Image:<br>Recommended <span id="recommended-size"></span>
            <br><br>
          </div>
          <button type="button" class="btn btn-primary" id="uploader">
            Select file
          </button>
        </div>
        <div class="form-group" id="uploaded-image-group" style="display:none;"></div>
        <div class="form-group" id="alt-text-group" style="display:none;">
          <label for="title"><strong></strong> (Describe the image)</label>
          <input class="form-control" required = true placeholder="Alt text" name="alt" type="text" id="alt">
        </div>
      </form>
    `;

    if (obj) {
      if (obj.type === 'image') {
        const modalId = 'upload-modal';
        console.error('UPLOADER FORM UI FUNCTIONS');
        MatgenGlobal.M4CModal.show({
          id: modalId,
          title: 'Upload Image',
          content: uploaderForm,
          buttons: [
            {
              id: 'save-upload',
              classname: 'primary btn btn-primary',
              label: 'Upload &amp; Save',
            },
          ],
        });

        MatgenGlobal.UI.initUploader(id, obj);

        $('#save-upload').off('click');
        $('#save-upload').on('click', async () => {
          if (
            !$('#uploaded-image-durl').val() ||
            $('#uploaded-image-durl').val() === ''
          ) {
            MatgenGlobal.UI.handleError(
              'Upload selection required',
              'You must select an image to upload.'
            );
            return false;
          }
          if ($('#image-upload-form')[0].checkValidity()) {
            const obj = MatgenGlobal.editor
              .cur()
              .fabric.getObjects()
              .find(o => o.id === $('#uploaded-image-obj-id').val());
            obj.altText = $('#alt').val();

            MatgenGlobal.UI.replaceCanvasImage(
              obj,
              $('#uploaded-image-durl').val()
            );
            $(`#${id}`).modal('hide');

            const oldId = obj.id;
            const newId = UUID();
            obj.set('id', newId);

            obj.set('currentOptionId', newId);
            MatgenGlobal.editor.cur().fabric.renderAll();
            $(`#${modalId}`).modal('hide');
            let openItems = sessionStorage.getItem('matgen-tree-state');
            if (!openItems) {
              openItems = [];
            } else {
              openItems = JSON.parse(openItems);
            }
            if (openItems.includes(oldId)) {
              openItems.splice(openItems.indexOf(oldId), 1);
              openItems.push(obj.id);
            }
            sessionStorage.setItem(
              'matgen-tree-state',
              JSON.stringify(openItems)
            );
            await MatgenGlobal.MatgenPageLoader.start({
              message: 'Saving option',
              promise: MatgenGlobal.UI.saveOption(obj),
            });
          } else {
            $('#image-upload-form')[0].reportValidity();
          }
        });
      } else if (obj.type === 'textbox') {
        MatgenGlobal.UI.textForm({ edit: false, id: obj.id });
      }
    }
  }

  static async viewTemplate(id) {
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(id),
    });
    if (['PDF', 'IMAGE'].includes(template.type)) {
      const pages = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading pages',
        promise: MatgenGlobal.Data.getPages(id),
      });
      const pageThumbs = pages
        .sort((a, b) => a.number - b.number)
        .map(p => p.id);
      const image = new Image();
      const ext = '.png';
      image.src = MatgenGlobal.Data.getTemplateFileURL(
        pageThumbs[0],
        template.tenant_id,
        ext
      );

      image.onload = () => {
        image.onload = null;
        const img = $(image.outerHTML);
        img.css({
          'max-width': image.width / 2,
          margin: 'auto',
        });
        img.attr('id', 'page-thumb');
        img.attr('alt', 'Preview of template');
        let content = img;
        if (pageThumbs.length > 1) {
          const wrapper = $('<div class="preview-thumb" id="preview-thumb" />');
          wrapper.append(img);
          wrapper.append(
            $(
              `
                ${
                  pageThumbs.length > 1
                    ? `
                  <div id="thumb-pager" class="text-center">
                  ${pageThumbs
                    .map(
                      (t, i) => `
                      ${i === 0 ? '' : ' - '}
                      <a href="#" data-id="${t}" data-tenant-id="${
                        template.tenant_id
                      }" data-index="${i}" class="thumb-page">${i + 1}</a>
                      `
                    )
                    .join('')}
                  </div>
                  `
                    : ''
                }
                </div>`
            )
          );
          content = wrapper;
        }

        MatgenGlobal.UI.alertModal(
          'Template Preview',
          content[0].outerHTML,
          null,
          'preview-modal'
        );
      };
    } else if (template.type === 'FILE' || template.type === 'VIDEO') {
      if (template.preview_type === 'IMAGE') {
        const image = new Image();
        image.src = MatgenGlobal.Data.getTemplateFileURL(
          template.id,
          template.tenant_id,
          template.preview_image_ext
        );
        image.onload = () => {
          image.onload = null;
          const img = $(image.outerHTML);
          img.css({
            'max-width': image.width / 2,
            margin: 'auto',
          });
          img.attr('id', 'page-thumb');
          img.attr('alt', 'Preview of material');
          let content = img;

          const wrapper = $('<div class="preview-thumb" id="preview-thumb" />');
          wrapper.append(img);
          content = wrapper;

          MatgenGlobal.UI.alertModal(
            null,
            content[0].outerHTML,
            null,
            'preview-modal'
          );
        };
      } else if (template.preview_type === 'LINK') {
        delete MatgenGlobal.generating;
        window.open(template.preview_link);
      } else if (template.preview_type === 'SELF') {
        const fileURL = MatgenGlobal.Data.getTemplateFileURL(
          template.id,
          template.tenant_id,
          template.file_ext
        );
        delete MatgenGlobal.generating;
        window.open(fileURL);
      } else {
        console.error('Bad template type:', template);
        MatgenGlobal.UI.handleError(
          'Unknown template type',
          'The system does not recognize the given template type.'
        );
      }
    } else {
      console.error('Bad template type:', template);
      MatgenGlobal.UI.handleError(
        'Unknown template type',
        'The system does not recognize the given template type.'
      );
    }
  }

  static async editReport(id, row) {
    let report = false;
    let newReport = false;
    if (id) {
      report = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading report',
        promise: MatgenGlobal.Data.getReport(id),
      });
    } else {
      MatgenForms.TemplateForm.reset();
      id = UUID();
      newReport = true;
    }

    MatgenUIFunctions.modalForm({
      prefix: 'report',
      inputs: [
        new TextInput({
          type: 'text',
          label: 'Name',
          dataId: 'name',
          id: 'inputName',
          required: true,
          autofocus: true,
        }),
        new TextAreaInput({
          label: 'Data',
          id: 'inputCode',
          dataId: 'code',
        }),
      ],
      title: newReport ? 'Add Report' : 'Edit Report',
      data: report ? report : false,
      options: { inline: false },
      actions: [
        {
          id: 'report-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Update',
        },
      ],
      listeners: () => {
        $(document).off('click', '#report-form-submit');
        $(document).on('click', '#report-form-submit', () => {
          $('#report-form').submit();
        });

        $(document).off('submit', '#report-form');
        $(document).on('submit', '#report-form', async e => {
          e.preventDefault();

          if ($('#report-form')[0].checkValidity()) {
            try {
              await MatgenGlobal.MatgenPageLoader.start({
                message: 'Saving report',
                promise: MatgenGlobal.Data.saveReport(
                  {
                    name: $('#inputName').val(),
                    code: $('#inputCode').val(),
                    id: id,
                  },
                  !newReport
                ),
              });

              if (newReport) {
                MatgenGlobal.Router.core.reload();
              }
            } catch (e) {
              console.error(e);
              MatgenGlobal.UI.handleError(
                'Error Saving report',
                'There was an error saving the report.'
              );
            }
            MatgenGlobal.Tables.ReportTable.updateCellData(
              row,
              1,
              $('#inputName').val()
            );
            $(`#report-form-modal`).modal('hide');
          } else {
            $(`#report-form`)[0].reportValidity();
          }
        });
      },
    });
  }

  static async editTemplateName(id) {
    let template = false;
    if (id) {
      template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template',
        promise: MatgenGlobal.Data.getTemplate(id),
      });
    }

    MatgenUIFunctions.modalForm({
      prefix: 'template',
      inputs: [
        new TextInput({
          type: 'text',
          label: 'Name',
          dataId: 'name',
          id: 'inputName',
          required: true,
          autofocus: true,
        }),
      ],
      title: 'Edit Template Name',
      data: template ? template : false,
      options: { inline: false },
      actions: [
        {
          id: 'template-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Update',
        },
      ],
      /*listeners: () => {
        $(document).off('click', '#template-form-submit');
        $(document).on('click', '#template-form-submit', () => {
          $('#template-form').submit();
        });

        $(document).off('submit', '#template-form');
        $(document).on('submit', '#template-form', async e => {
          e.preventDefault();

          if ($('#template-form')[0].checkValidity()) {
            try {
              await MatgenGlobal.MatgenPageLoader.start({
                message: 'Saving template',
                promise: MatgenGlobal.Data.saveTemplate(
                  {
                    name: $('#inputName').val(),
                    text_length: $('#inputTextLength').val(),
                    //subtitle: $('#inputTemplateSubtitle').val(),
                    //youtubeURL: $('#youtube_url').val(),
                    //instructions: $('#inputInstructions').val(),
                    //description: $('#inputDescription').val(),
                    //modifiable: $('inputComponents').val(),
                    id: $('#template-data-id').val(),
                    tenant_id: template.tenant_id,
                  },
                  true
                ),
              });
            } catch (e) {
              console.error(e);
              MatgenGlobal.UI.handleError(
                'Error Saving Template',
                'There was an error saving the template.'
              );
            }
            MatgenGlobal.Tables.TemplateTable.updateCellData(
              row,
              1,
              $('#inputName').val()
            );
            $(`#template-form-modal`).modal('hide');
          } else {
            $(`#template-form`)[0].reportValidity();
          }
        });
      },*/
    });
  }

  static async editTemplateData(id, tenant_id) {
    let type, template;

    if (id) {
      template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template',
        promise: MatgenGlobal.Data.getTemplate(id, tenant_id),
      });
      type = template.type;
      MatgenGlobal.tmp = MatgenForms.TemplateForm;
      MatgenForms.TemplateForm.setValues(template);
    } else {
      MatgenForms.TemplateForm.reset();
    }

    MatgenGlobal.UI.showFormModal({
      modalId: 'template-form-modal',
      formId: 'template-form',
      title: id ? 'Edit Template' : 'Create Template',
      content: await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading form content',
        promise: MatgenForms.TemplateForm.getHTML({
          inline: false,
          hide: id ? ['type', 'tenant'] : [],
        }),
      }),
      actions: [
        {
          id: 'template-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
    });

    new M4CRichTextEditor({
      id: 'inputComponentsRTE',
      targetSelector: '#inputComponents',
      changeHandler: e => {
        console.log(JSON.stringify(e));
      },
    });

    if (id) {
      $('#template-form').append(
        $(`
           <input type="hidden" id="inputTemplateID" name="inputTemplateID" value="${id}" />
         `)
      );
    }
    if (!template || (template.type !== 'FILE' && template.type !== 'VIDEO')) {
      $('#inputPreviewType')
        .removeAttr('required')
        .closest('.row')
        .hide();
      $('#inputPreviewLink')
        .removeAttr('required')
        .closest('.row')
        .hide();
    }
    if (!template || template.type === 'FILE' || template.type === 'VIDEO') {
      $('#inputWidth')
        .removeAttr('required')
        .closest('.row')
        .hide();
      $('#inputHeight')
        .removeAttr('required')
        .closest('.row')
        .hide();
      if (template && template.preview_type === 'LINK') {
        $('#inputPreviewLink')
          .attr('required', true)
          .closest('.row')
          .show();
      }

      $('#youtubeURL').removeAttr('required');
      $('#youtubeURL')
        .closest('.row')
        .hide();
    }
    if (type) {
      $('#template-form').append(
        $(`
         <input type="hidden" id="inputTemplateType" name="inputTemplateType" value="${type}" />
       `)
      );
    }
  }

  static async togglePublished(id, type = 'template') {
    let published = false;
    if (type === 'microsite') {
      let microsite;
      try {
        microsite = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading website',
          promise: MatgenGlobal.Data.getMicrosite(id),
        });
      } catch (e) {
        console.error(e);
        return false;
      }
      if (!microsite || microsite.length === 0) {
        console.error(Error('Microsite not found'));
        return false;
      } else {
        published = !microsite.published;
        try {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Saving website',
            promise: MatgenGlobal.Data.API.request(`/microsites`, 'POST', {
              id: microsite.id,
              subdomain: microsite.subdomain,
              user_name: microsite.user_name,
              study_id: microsite.study_id,
              published,
              edit: true,
            }),
          });
          if (published) {
            $(`[data-id="${id}"]`).removeClass('btn-warning');
            $(`[data-id="${id}"]`).addClass('btn-primary');
          } else {
            $(`[data-id="${id}"]`).removeClass('btn-primary');
            $(`[data-id="${id}"]`).addClass('btn-warning');
          }
        } catch (e) {
          console.error(e);
          return false;
        }
      }
    } else {
      let template;
      try {
        template = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading template',
          promise: MatgenGlobal.Data.getTemplate(id),
        });
      } catch (e) {
        console.error(e);
        return false;
      }
      if (!template) {
        console.error(Error('Template not found'));
      } else {
        published = !template.published;
        try {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Saving template',
            promise: MatgenGlobal.Data.saveTemplate(
              {
                id: template.id,
                tenant_id: template.tenant_id,
                published,
              },
              true
            ),
          });
        } catch (e) {
          console.error(e);
          return false;
        }
      }
    }

    if (published) {
      $(`.table-action[data-action=template-toggle-publish][data-id=${id}]`)
        .closest('tr')
        .removeClass('unpublished');
    } else {
      $(`.table-action[data-action=template-toggle-publish][data-id=${id}]`)
        .closest('tr')
        .addClass('unpublished');
    }
    $(
      `.table-action[data-action=template-toggle-publish][data-id=${id}]`
    ).replaceWith(
      $(
        `
       <button type="button" data-bs-toggle="tooltip" data-placement="auto" title="${
         published ? 'Unpublish' : 'Publish'
       }" data-action="template-toggle-publish" data-id="${id}" class="btn btn-sm ${
          published ? 'btn-primary' : 'btn-warning'
        } table-action">
         <i class="${published ? 'fas fa-eye-slash' : 'fas fa-eye'}"></i>
       </button>
     `
      )
    );
  }

  static async toggleReview(id, tenant_id) {
    let review = false;
    let template;
    try {
      template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template',
        promise: MatgenGlobal.Data.getTemplate(id, tenant_id),
      });
    } catch (e) {
      console.error(e);
      return false;
    }
    if (!template) {
      console.error(Error('Template not found'));
    } else {
      review = !template.review;
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving template',
          promise: MatgenGlobal.Data.saveTemplate(
            {
              id: template.id,
              tenant_id: template.tenant_id,
              review,
            },
            true
          ),
        });
      } catch (e) {
        console.error(e);
        return false;
      }
    }

    if (review) {
      $(`.table-action[data-action=template-review][data-id=${id}]`)
        .removeClass('btn-warning')
        .addClass('btn-danger');
    } else {
      $(`.table-action[data-action=template-review][data-id=${id}]`)
        .removeClass('btn-danger')
        .addClass('btn-warning');
    }
  }

  static async saveTemplateDescription() {
    let template;
    try {
      template = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template',
        promise: MatgenGlobal.Data.getTemplate(
          $('#template-description-id').val()
        ),
      });
    } catch (e) {
      console.error(e);
      return false;
    }
    if (!template) {
      console.error(Error('Template not found'));
    } else {
      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving template',
          promise: MatgenGlobal.Data.saveTemplate(
            {
              id: template.id,
              tenant_id: template.tenant_id,
              description: JSON.stringify({
                html: M4CGlobal.quill['template-description'].root.innerHTML,
                plain: M4CGlobal.quill['template-description'].getText(),
                data: M4CGlobal.quill['template-description'].getContents(),
              }),
              modifiable: JSON.stringify({
                html: M4CGlobal.quill['template-modifiable'].root.innerHTML,
                plain: M4CGlobal.quill['template-modifiable'].getText(),
                data: M4CGlobal.quill['template-modifiable'].getContents(),
              }),
            },
            true
          ),
        });
        const modal = bootstrap.Modal.getOrCreateInstance(
          `#edit-description-modal`
        );
        modal.hide();

        if (
          M4CGlobal.quill['template-description'].getText() !== '' &&
          M4CGlobal.quill['template-description'].getText() !== '\n' &&
          M4CGlobal.quill['template-modifiable'].getText() !== '' &&
          M4CGlobal.quill['template-modifiable'].getText() !== '\n'
        ) {
          $(`[data-action="template-description"][data-id="${template.id}"]`)
            .removeClass('btn-warning')
            .addClass('btn-primary');
        } else {
          $(`[data-action="template-description"][data-id="${template.id}"]`)
            .removeClass('btn-primary')
            .addClass('btn-warning');
        }
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Error Saving Template',
          'There was an error saving the template.'
        );
        return false;
      }
    }
  }

  static async deleteTenant(id) {
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting tenant',
      promise: MatgenGlobal.Data.deleteTenant(id),
    });
    MatgenGlobal.Router.core.reload();
  }

  static async deleteAnswer(id) {
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting answer',
      promise: MatgenGlobal.Data.deleteAnswer(id),
    });
    MatgenGlobal.Router.core.reload();
  }

  static async deleteQuestion(id) {
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting question',
      promise: MatgenGlobal.Data.deleteQuestion(id),
    });
    MatgenGlobal.Router.core.reload();
  }

  static async deleteSection(id) {
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting section',
      promise: MatgenGlobal.Data.deleteSection(id),
    });
    MatgenGlobal.Router.core.reload();
  }

  static async deleteQuestionnaire(id) {
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading questionnaire',
      promise: MatgenGlobal.Data.deleteQuestionnaire(id),
    });
    MatgenGlobal.Router.core.reload();
  }

  static async getTemplateExport(id) {
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(id),
    });
    if (template === false) {
      return false;
    }

    const pages = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template pages',
      promise: MatgenGlobal.Data.getPages(id),
    });
    if (pages === false) {
      return false;
    }

    const dataComponents = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template components',
      promise: MatgenGlobal.Data.getComponents(id),
    });
    if (dataComponents === false) {
      return false;
    }

    let usedComponents = [];

    for (let i = 0; i < pages.length; i++) {
      const page = await MatgenGlobal.Data.getTemplateFile(
        pages[i].id,
        template.tenant_id
      );
      if (page.error !== true) {
        const used = page.objects.map(o => o.componentId).filter(o => o);
        usedComponents = usedComponents.concat(used);
      } else {
        throw new Error('Error loading page');
      }
    }

    const components = [];
    for (let i = 0; i < dataComponents.length; i++) {
      if (usedComponents.includes(dataComponents[i].id)) {
        components.push(dataComponents[i]);
      }
    }

    const options = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template component options',
      promise: Promise.all(
        components.map(c => MatgenGlobal.Data.getComponentOptions(c.id))
      ),
    });

    for (let i = 0; i < components.length; i++) {
      components[i].options = options.find(o =>
        o.find(opt => opt.component_id === components[i].id)
      );
    }

    const pageFiles = {};
    for (let i = 0; i < pages.length; i++) {
      pageFiles[pages[i].id] = {};
      const pageJSON = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template page JSON',
        promise: MatgenGlobal.Data.API.getS3File({
          path: `tenant/${template.tenant_id}/templates/${pages[i].id}.json`,
          type: 'json',
          customPrefix: { public: '' },
        }),
      });
      pageFiles[pages[i].id].json = pageJSON;

      const pagePreview = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading template page preview',
        promise: MatgenGlobal.Data.API.getS3File({
          path: `tenant/${template.tenant_id}/templates/${pages[i].id}.png`,
          customPrefix: { public: '' },
        }),
      });
      pageFiles[pages[i].id].preview = pagePreview;
    }

    const optionFiles = {};

    for (let i = 0; i < options.length; i++) {
      for (let j = 0; j < options[i].length; j++) {
        optionFiles[options[i][j].id] = {};
        const optionJSON = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading component option JSON',
          promise: MatgenGlobal.Data.API.getS3File({
            path: `tenant/${template.tenant_id}/options/${options[i][j].id}.json`,
            type: 'json',
            customPrefix: { public: '' },
          }),
        });
        optionFiles[options[i][j].id].json = optionJSON;

        const optionPreview = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading component option preview',
          promise: MatgenGlobal.Data.API.getS3File({
            path: `tenant/${template.tenant_id}/options/${options[i][j].id}.png`,
            customPrefix: { public: '' },
          }),
        });
        optionFiles[options[i][j].id].preview = optionPreview;
      }
    }
    return {
      id,
      template,
      pages,
      components,
      pageFiles,
      optionFiles,
    };
  }

  static async getZippedFile(zip, filename) {
    return new Promise(resolve => {
      zip.files[filename].async('string').then(fileData => {
        resolve(fileData);
      });
    });
  }

  static async processZip(zip, filename, templateData, pages, options) {
    const parts = filename.replace(`${templateData.id}/`, '').split('/');
    if (parts.length === 1 && parts[0] !== '') {
      if (parts[0] === 'template.json') {
        const template = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading template',
          promise: MatgenGlobal.MatgenUIFunctions.getZippedFile(zip, filename),
        });
        templateData.template = JSON.parse(template);
      } else if (parts[0] === 'pages.json') {
        const pages = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading pages',
          promise: MatgenGlobal.MatgenUIFunctions.getZippedFile(zip, filename),
        });
        templateData.pages = JSON.parse(pages);
      } else if (parts[0] === 'components.json') {
        const components = await MatgenGlobal.MatgenPageLoader.start({
          message: 'Loading components',
          promise: MatgenGlobal.MatgenUIFunctions.getZippedFile(zip, filename),
        });
        templateData.components = JSON.parse(components);
      } else {
        const file = await MatgenGlobal.MatgenPageLoader.start({
          message: `Loading file ${filename}`,
          promise: MatgenGlobal.MatgenUIFunctions.getZippedFile(zip, filename),
        });
        if (filename.includes('.json')) {
          if (!pages[parts[parts.length - 1].replace('.json', '')]) {
            pages[parts[parts.length - 1].replace('.json', '')] = {};
          }
          pages[parts[parts.length - 1].replace('.json', '')].json = file;
        } else {
          if (!pages[parts[parts.length - 1].replace('.png', '')]) {
            pages[parts[parts.length - 1].replace('.png', '')] = {};
          }
          pages[parts[parts.length - 1].replace('.png', '')].preview = file;
        }
      }
    } else if (parts[parts.length - 1] !== '') {
      if (parts[0] === 'options') {
        const file = await MatgenGlobal.MatgenPageLoader.start({
          message: `Loading file ${filename}`,
          promise: MatgenGlobal.MatgenUIFunctions.getZippedFile(zip, filename),
        });
        if (filename.includes('.json')) {
          if (!options[parts[parts.length - 1].replace('.json', '')]) {
            options[parts[parts.length - 1].replace('.json', '')] = {};
          }
          options[parts[parts.length - 1].replace('.json', '')].json = file;
        } else {
          if (!options[parts[parts.length - 1].replace('.png', '')]) {
            options[parts[parts.length - 1].replace('.png', '')] = {};
          }
          options[parts[parts.length - 1].replace('.png', '')].preview = file;
        }
      }
    }
  }

  static async importTemplate(zip) {
    const jsZip = new JSZip();
    const template_id = zip.name.replace('.zip', '');
    const templateData = {
      id: template_id,
      pageFiles: [],
      optionFiles: [],
    };

    const pages = {};
    const options = {};
    const unzipped = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading imported template',
      promise: jsZip.loadAsync(zip),
    });

    const filenames = Object.keys(unzipped.files);
    for (let i = 0; i < filenames.length; i++) {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Importing template',
        promise: MatgenGlobal.MatgenUIFunctions.processZip(
          unzipped,
          filenames[i],
          templateData,
          pages,
          options
        ),
      });
    }
    templateData.pageFiles = pages;
    templateData.optionFiles = options;
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Saving imported template',
      promise: MatgenUIFunctions.saveExport(templateData),
    });
  }

  static async zipTemplate(templateData) {
    const zip = new JSZip();
    const dir = zip.folder(templateData.id);

    dir.file('template.json', JSON.stringify(templateData.template));
    dir.file('pages.json', JSON.stringify(templateData.pages));
    dir.file('components.json', JSON.stringify(templateData.components));

    const pages = Object.keys(templateData.pageFiles);
    for (let i = 0; i < pages.length; i++) {
      const json = JSON.stringify(templateData.pageFiles[pages[i]].json);
      dir.file(`${pages[i]}.json`, json);
      dir.file(`${pages[i]}.png`, templateData.pageFiles[pages[i]].preview);
    }

    const options = Object.keys(templateData.optionFiles);
    const opts = dir.folder('options');

    for (let i = 0; i < options.length; i++) {
      const json = JSON.stringify(templateData.optionFiles[options[i]].json);
      opts.file(`${options[i]}.json`, json);
      opts.file(
        `${options[i]}.png`,
        templateData.optionFiles[options[i]].preview
      );
    }

    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Zipping template',
      promise: new Promise((resolve, reject) => {
        zip
          .generateAsync({ type: 'blob' })
          .then(content => {
            saveAs(content, `${templateData.id}.zip`);
            resolve(content);
          })
          .catch(e => {
            console.error(e);
            reject(e);
          });
      }),
    });
  }

  static async copyExport(exp, name) {
    const copy = merge({}, exp);
    const newTemplateId = UUID();
    const pageIdMap = {};
    const componentIdMap = {};
    const optionIdMap = {};

    copy.id = newTemplateId;
    copy.template.id = newTemplateId;
    copy.template.name = name;

    copy.pages = copy.pages.map(p => {
      const newPageId = UUID();
      pageIdMap[p.id] = newPageId;
      return {
        id: newPageId,
        template_id: newTemplateId,
        number: p.number,
      };
    });

    copy.components = copy.components.map(c => {
      const component = { ...c };
      const newComponentId = UUID();
      let defaultFound = false;
      componentIdMap[component.id] = newComponentId;
      component.id = newComponentId;
      component.template_id = newTemplateId;
      component.options = component.options
        ? component.options.map(o => {
            const option = { ...o };
            const newOptionId = UUID();
            optionIdMap[option.id] = newOptionId;
            option.id = newOptionId;
            option.component_id = newComponentId;
            if (o.id === component.default_option_id) {
              component.default_option_id = newOptionId;
              defaultFound = true;
            }
            return option;
          })
        : [];
      if (!defaultFound) {
        component.default_option_id =
          component.options && component.options[0]
            ? component.options[0].id
            : false;
      }
      return component;
    });

    const newPageFiles = {};
    const pages = Object.keys(copy.pageFiles);
    for (let i = 0; i < pages.length; i++) {
      newPageFiles[pageIdMap[pages[i]]] = {};
      const pageJSON = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading page JSON',
        promise: MatgenGlobal.Data.API.getS3File({
          path: `tenant/${copy.template.tenant_id}/templates/${pages[i]}.json`,
          type: 'json',
          customPrefix: { public: '' },
        }),
      });
      const json = JSON.stringify(pageJSON);
      const json2 = replaceIds(json, {
        id: copy.template.id,
        newTemplateId,
        pageIdMap,
        componentIdMap,
        optionIdMap,
      });
      newPageFiles[pageIdMap[pages[i]]].json = json2;

      const pagePreview = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading page preview',
        promise: MatgenGlobal.Data.API.getS3File({
          path: `tenant/${copy.template.tenant_id}/templates/${pages[i]}.png`,
          customPrefix: { public: '' },
        }),
      });
      newPageFiles[pageIdMap[pages[i]]].preview = pagePreview;
    }

    copy.pageFiles = newPageFiles;

    const newOptionFiles = {};
    const options = Object.keys(copy.optionFiles);
    for (let i = 0; i < options.length; i++) {
      newOptionFiles[optionIdMap[options[i]]] = {};
      const optionJSON = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading option JSON',
        promise: MatgenGlobal.Data.API.getS3File({
          path: `tenant/${copy.template.tenant_id}/options/${options[i]}.json`,
          type: 'json',
          customPrefix: { public: '' },
        }),
      });
      const json = JSON.stringify(optionJSON);
      const json2 = replaceIds(json, {
        id: copy.template.id,
        newTemplateId,
        pageIdMap,
        componentIdMap,
        optionIdMap,
      });
      newOptionFiles[optionIdMap[options[i]]].json = json2;

      const optionPreview = await MatgenGlobal.MatgenPageLoader.start({
        message: 'Loading option preview',
        promise: MatgenGlobal.Data.API.getS3File({
          path: `tenant/${copy.template.tenant_id}/options/${options[i]}.png`,
          customPrefix: { public: '' },
        }),
      });
      newOptionFiles[optionIdMap[options[i]]].preview = optionPreview;
    }
    copy.optionFiles = newOptionFiles;

    return copy;
  }

  static async saveQueue(a) {
    const item = a.pop();
    if (!item.data || item.data === '') {
      return false;
    }
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Saving template file',
      promise: MatgenGlobal.Data.saveTemplateFile(
        item.name,
        item.tenant_id,
        item.data,
        item.option
      ),
    });
    if (a.length > 0) {
      window.setTimeout(() => MatgenUIFunctions.saveQueue(a), 25);
    } else {
      window.location.reload();
    }
  }

  static async saveExport(exp) {
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Saving template',
      promise: MatgenGlobal.Data.saveTemplate(exp.template),
    });
    for (let i = 0; i < exp.pages.length; i++) {
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving template page',
        promise: MatgenGlobal.Data.savePage(exp.pages[i]),
      });
    }
    for (let i = 0; i < exp.components.length; i++) {
      const c = merge({}, exp.components[i]);
      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Saving component',
        promise: MatgenGlobal.Data.saveComponent(c),
      });
      if (c.options) {
        const opts = c.options;
        delete c.options;
        for (let j = 0; j < opts.length; j++) {
          await MatgenGlobal.MatgenPageLoader.start({
            message: 'Saving option',
            promise: MatgenGlobal.Data.saveOptionData(opts[j]),
          });
        }
      }
    }
    const pages = Object.keys(exp.pageFiles);
    const throttledRequests = [];
    for (let i = 0; i < pages.length; i++) {
      throttledRequests.push({
        name: `${pages[i]}.json`,
        tenant_id: exp.template.tenant_id,
        data: exp.pageFiles[pages[i]].json,
      });
      throttledRequests.push({
        name: `${pages[i]}.png`,
        tenant_id: exp.template.tenant_id,
        data: exp.pageFiles[pages[i]].preview,
      });
    }
    const options = Object.keys(exp.optionFiles);

    for (let i = 0; i < options.length; i++) {
      throttledRequests.push({
        name: `${options[i]}.json`,
        tenant_id: exp.template.tenant_id,
        data: exp.optionFiles[options[i]].json,
        option: true,
      });
      throttledRequests.push({
        name: `${options[i]}.png`,
        tenant_id: exp.template.tenant_id,
        data: exp.optionFiles[options[i]].preview,
        option: true,
      });
    }
    MatgenUIFunctions.saveQueue(throttledRequests);
  }

  static async exportTemplate(id) {
    const exp = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Retrieving template data',
      promise: MatgenUIFunctions.getTemplateExport(id),
    });
    MatgenUIFunctions.zipTemplate(exp);
  }

  static async duplicateTemplate(id, name) {
    const exp = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Retrieving template data',
      promise: MatgenUIFunctions.getTemplateExport(id),
    });

    const copy = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Duplicating template',
      promise: MatgenUIFunctions.copyExport(exp, name),
    });
    await MatgenGlobal.MatgenPageLoader.start({
      message: 'Saving duplicated template',
      promise: MatgenUIFunctions.saveExport(copy),
    });
  }

  static async deleteTemplate(id) {
    const response = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting template',
      promise: MatgenGlobal.Data.deleteTemplate(id),
    });
    if (response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem deleting the template.'
      );
      return false;
    }
    MatgenGlobal.Router.core.reload();
  }

  static async deleteReport(id) {
    const response = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Deleting report',
      promise: MatgenGlobal.Data.deleteReport(id),
    });
    if (response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem deleting the report.'
      );
      return false;
    }
    MatgenGlobal.Router.core.reload();
  }

  static async modalForm({
    prefix,
    inputs,
    title,
    actions = false,
    data = false,
    options = {},
    listeners = false,
    hiddenFields = [],
    noReset = false,
  } = {}) {
    const form = new Form({
      inputs,
      id: `${prefix}-form`,
      title,
    });

    if (data) {
      form.setValues(data);
    } else {
      if (!noReset) {
        form.reset();
      }
    }

    const formHTML = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: form.getHTML(options),
    });
    MatgenGlobal.UI.showFormModal({
      modalId: `${prefix}-form-modal`,
      formId: `${prefix}-form`,
      title,
      content: formHTML,
      actions,
    });

    if (data && data.id) {
      $(`#${prefix}-form`).append(
        $(`
           <input type="hidden" id="${prefix}-data-id" name="${prefix}-data-id" value="${data.id}" />
         `)
      );
    }

    if (hiddenFields) {
      hiddenFields.forEach(i => {
        $(`#${prefix}-form`).append(
          $(`
             <input type="hidden" id="${i.name}" name="${i.name}" value="${i.value}" />
           `)
        );
      });
    }

    if (listeners && typeof listeners === 'function') {
      listeners();
    }
  }

  static async modalFormUI({
    title,
    top_content,
    bottom_content,
    inputs,
    buttons = [],
    idPrefix,
    width,
  } = {}) {
    const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: new MatgenForms.DynamicForm(inputs, [], `${idPrefix}-form`),
    });
    const content = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: DynamicForm.form.getElement(),
    });
    MatgenGlobal.M4CModal.show({
      id: `${idPrefix}-modal`,
      title,
      content: `
            ${top_content ? top_content : ''}
            ${content[0].outerHTML}
            ${bottom_content ? bottom_content : ''}
          `,
      buttons,
      width,
      closeText: 'Cancel',
    });
  }

  static async userModal({ user } = {}) {
    const token = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading session token',
      promise: MatgenGlobal.AuthUser.getSessionToken(),
    });
    const opts = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading user',
      promise: MatgenGlobal.Amplify.API.get(
        'authenticated',
        `/users/${user.id}`,
        opts
      ),
    });
    const groups = response.groups.Groups.map(g => g.GroupName);
    const m = MatgenGlobal.UI.modal(
      'usermodal',
      'Edit User',
      `
        <label for="user-email" class="sr-only">Email address</label>
        <input
          type="email"
          id="user-email-input"
          name="user-email-input"
          class="form-control"
          placeholder="Email address"
          required
          autofocus
          ${user ? `value="${user.email}"` : ''}
        />
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="admin-role" ${
            groups.includes('admin') ? 'checked' : ''
          }>
          <label class="form-check-label" for="admin-role">
            Admin
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="checkbox" value="" id="microsite-editor-role" ${
            groups.includes('NIA-Microsite-Editor-Group') ? 'checked' : ''
          }>
          <label class="form-check-label" for="microsite-editor-role">
            Microsite Editor
          </label>
        </div>
        <input type="hidden" id="user-id" value="${user.id}" />
        `,
      false,
      [
        {
          id: `usermodal-submit-button`,
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ]
    );
    $('body').append(m.markup);
    $(`#usermodal`).modal('show');

    $(`#usermodal-submit-button`).off('click');
    $(`#usermodal-submit-button`).on('click', async () => {
      const id = $('#user-id').val();
      const email = $('#user-email-input').val();
      const admin = $('#admin-role').is(':checked');
      const micrositeEditor = $('#microsite-editor-role').is(':checked');
      const data = {
        id,
        email,
        admin,
        micrositeEditor,
      };

      const opts = {
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        await MatgenGlobal.MatgenPageLoader.start({
          message: 'Saving user',
          promise: MatgenGlobal.Amplify.API.patch(
            'authenticated',
            `/users`,
            opts
          ),
        });
        MatgenGlobal.UI.alertModal('User Saved', `The user has been saved.`);
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.alertModal(
          'Error Saving User',
          `There was an error saving the user data.`
        );
      }
      $(`#usermodal`).modal('toggle');
    });
  }

  static async showLoginModal() {
    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Email',
          id: 'm4c-login-email',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'password',
          label: 'Password',
          id: 'm4c-login-password',
        },
      },
    ];
    const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: new MatgenForms.DynamicForm(inputs, [], 'm4c-login-form'),
    });
    const content = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: DynamicForm.form.getElement(),
    });
    MatgenGlobal.M4CModal.show({
      id: 'matgen-signin-modal',
      title: `Log In`,
      content: `
      <div class="row">
        <div class="col-sm-6">
          <p>
            <a id="matgen-forgot-password-link" href="#" class="link-primary">Forgot password</a>
          </p>
        </div>
        <div class="col-sm-6">
          <p style="text-align:right;">
            <a id="matgen-reset-password-link" href="#" class="link-primary">Enter confirmation code</a>
          </p>
        </div>
      </div>

      <!--<div class="row form-error"></div>-->

      <div class="row">
        ${content[0].outerHTML}
      </div>

      <div class="row">
        <div class="col-sm-6">
          <p style="margin-top:1rem;">
            <a id="matgen-resend-confirmation-link" href="#" class="link-primary"style="display:none;">Resend Email Verification</a>
          </p>
        </div>
        <div class="col-sm-6">
          <p style="text-align:right;margin-top:1rem;">
            <a id="matgen-login-signup-link" href="#" class="link-primary">
          Create Account</a>
          </p>
        </div>
      </div>

      <!--<div class="row">
        <div class="col-sm-12" style="margin-top:1rem;">
         <a id="matgen-login-signup-link" href="#" class="link-primary">
       Create Account</a>
        </a>
        </div>
      </div>-->
      `,
      buttons: [
        {
          id: `matgen-login-submit`,
          classname: 'primary btn btn-primary',
          label: `Log In`,
        },
      ],
      width: '550px',
    });
  }

  static async showSignupModal() {
    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Email',
          id: 'm4c-signup-email',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Confirm Email',
          id: 'm4c-signup-email-confirm',
        },
      },
      {
        component: 'RawHTML',
        html: `
          <div class="m-3 signup-form-password-rules">Password must be at least 8 characters long and contain at least one number, symbol, and uppercase letter.</div>
          `,
      },
      {
        component: 'Text',
        options: {
          type: 'password',
          label: 'Password',
          id: 'm4c-signup-password',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'password',
          label: 'Confirm Password',
          id: 'm4c-signup-password-confirm',
        },
      },
    ];
    const DynamicForm = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form',
      promise: new MatgenForms.DynamicForm(inputs, [], 'm4c-signup-form'),
    });
    const content = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading form content',
      promise: DynamicForm.form.getElement(),
    });
    MatgenGlobal.M4CModal.show({
      id: 'm4c-signup-modal',
      title: `Sign Up`,
      content: content[0].outerHTML,
      buttons: [
        {
          id: `m4c-signup-submit`,
          classname: 'primary btn btn-primary',
          label: `Sign Up`,
        },
      ],
      width: '550px',
    });
  }

  static async downloadImages(id) {
    const template = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading template',
      promise: MatgenGlobal.Data.getTemplate(id),
    });
    const pages = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading pages',
      promise: MatgenGlobal.Data.getPages(id),
    });
    pages.sort((a, b) => a.number - b.number);
    const json = await MatgenGlobal.MatgenPageLoader.start({
      message: 'Loading JSON',
      promise: MatgenGlobal.Data.getTemplateFile(
        pages[0].id,
        template.tenant_id
      ),
    });
    if (json.error !== true) {
      const images = json.objects.filter(
        o => (o.type === 'image' || o.type === 'group') && !o.richText
      );
      const zip = new JSZip();
      for (let k = 0; k < pages.length; k++) {
        for (let i = 0; i < images.length; i++) {
          if (images[i].componentId) {
            const options = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Loading options',
              promise: MatgenGlobal.Data.getComponentOptions(
                images[i].componentId
              ),
            });
            const tempJSON = await MatgenGlobal.MatgenPageLoader.start({
              message: 'Checking option type',
              promise: MatgenGlobal.Data.API.getS3File({
                path: `tenant/${template.tenant_id}/options/${options[0].id}.json`,
                type: 'json',
                customPrefix: { public: '' },
              }),
            });
            if (tempJSON.type === 'image') {
              const dir = zip.folder(images[i].name);
              for (let j = 0; j < options.length; j++) {
                const optionJSON = await MatgenGlobal.MatgenPageLoader.start({
                  message: 'Loading option JSON',
                  promise: MatgenGlobal.Data.API.getS3File({
                    path: `tenant/${template.tenant_id}/options/${options[j].id}.json`,
                    type: 'json',
                    customPrefix: { public: '' },
                  }),
                });
                const base64Data = new Buffer.from(
                  optionJSON.src.replace(/^data:image\/\w+;base64,/, ''),
                  'base64'
                );
                dir.file(`${options[j].id}.png`, base64Data);
              }
            }
          } else {
            const base64Data = new Buffer.from(
              images[i].src.replace(/^data:image\/\w+;base64,/, ''),
              'base64'
            );
            zip.file(`${images[i].id}.png`, base64Data);
          }
        }
      }

      await MatgenGlobal.MatgenPageLoader.start({
        message: 'Generating ZIP file',
        promise: new Promise((resolve, reject) => {
          zip
            .generateAsync({ type: 'blob' })
            .then(content => {
              saveAs(content, `${id}.zip`);
              resolve(content);
            })
            .catch(e => {
              console.error(e);
              reject(e);
            });
        }),
      });
    } else {
      MatgenGlobal.UI.handleError(
        'Error Loading Page JSON',
        'There was an error loading the JSON file for the template page.'
      );
    }
  }
}
